import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { statusToBadgeColor } from '@/helpers/ServiceActivityHelper.ts'

export function ServiceActivityStatusCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const status = ctx.getValue<string>()
  return <Badge color={statusToBadgeColor(status)}>{status}</Badge>
}
