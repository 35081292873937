import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { priorityToBadgeColor } from '@/helpers/ServiceActivityHelper.ts'
import { Priority } from '@/generated'

export function TicketPriorityCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const priority = ctx.getValue<Priority>()
  return <Badge color={priorityToBadgeColor(priority)}>{priority}</Badge>
}
