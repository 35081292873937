import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Authenticated } from './components/auth/Authenticated.tsx'
import { RequireFeature } from './components/feature-flags/RequireFeature.tsx'
import { MainLayout } from './components/layout/MainLayout.tsx'
import { DispatchApprovalPage } from './pages/DispatchApprovalPage.tsx'
import NotFoundPage from './pages/NotFoundPage.tsx'
import { PortfolioOverviewPage } from './pages/PortfolioOverviewPage.tsx'
import { ClientProfileSelectionPage } from './pages/ClientProfileSelectionPage.tsx'
import { RequireClientProfile } from './components/clientprofile/RequireClientProfile.tsx'
import { AssetDetailRoutes } from './pages/assetdetail/AssetDetailRoutes.tsx'
import { LoginCallback } from '@/components/auth/LoginCallback.tsx'
import { IndexDefaultRoute } from '@/IndexDefaultRoute.tsx'
import ServiceActivityPageWrapper from '@/pages/serviceActivity/ServiceActivityPageWrapper.tsx'

export function AppRoutes(): React.JSX.Element {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Authenticated>
            <RequireClientProfile>
              <MainLayout />
            </RequireClientProfile>
          </Authenticated>
        }
      >
        <Route index element={<IndexDefaultRoute />} />
        <Route
          path="portfolio"
          element={
            <RequireFeature
              feature="portfolioOverviewEnabled"
              denied={<NotFoundPage />}
            >
              <PortfolioOverviewPage />
            </RequireFeature>
          }
        />
        <Route
          path="home/awaiting-approval"
          element={
            <RequireFeature
              feature="dispatchApprovalEnabled"
              denied={<NotFoundPage />}
            >
              <DispatchApprovalPage />
            </RequireFeature>
          }
        />
        <Route
          path="service-activity"
          element={
            <RequireFeature
              feature="serviceActivityEnabled"
              denied={<NotFoundPage />}
            >
              <ServiceActivityPageWrapper />
            </RequireFeature>
          }
        />
        {AssetDetailRoutes}
      </Route>
      <Route
        path="/select-client"
        element={
          <Authenticated>
            <ClientProfileSelectionPage />
          </Authenticated>
        }
      />
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
