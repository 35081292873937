import { useOutletContext } from 'react-router-dom'
import { AssetDetailContext } from './AssetDetailPage.tsx'
import React, { useMemo, useState } from 'react'
import { ColumnDef, Row } from '@tanstack/react-table'
import { ServiceActivity } from '@/generated'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import {
  ticketPrioritySort,
  ticketPriorityTableRowSort,
} from '@/components/table/sort/ticketPrioritySort.ts'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { dateRangeFilterFn } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'
import { DateCell } from '@/components/core/table/cells/DateCell.tsx'
import { TicketStatusCell } from '@/components/table/cells/TicketStatusCell.tsx'
import { ExportCsvButton } from '@/components/export/ExportCsvButton.tsx'
import {
  FilterDrawerFilterDef,
  FiltersDrawerButton,
} from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useTableFilterState } from '@/components/core/table/filters/useTableFitlerState.ts'
import SearchInput from '@/components/core/table/SearchInput.tsx'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { ActiveFilters } from '@/components/core/drawers/filter/ActiveFilters.tsx'
import { LoadingPage } from '../LoadingPage.tsx'
import Table from '@/components/core/table/Table.tsx'
import {
  useAssetMetadataQuery,
  useAssetServiceActivityQuery,
} from '@/api/AssetQueries.ts'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'
import {
  closedDateFilter,
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  scheduledDateFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  subStatusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import {
  formatCSVRow,
  isServiceActivityClosed,
  serviceActivityStatusSort,
} from '@/helpers/ServiceActivityHelper.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { WorkOrdersCountCell } from '@/components/table/cells/WorkOrdersCountCell.tsx'
import { ServiceActivityStatusCell } from '@/components/table/cells/ServiceActivityStatusCell.tsx'
import moment from 'moment'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import useServiceActivityCsvColumns from '@/pages/serviceActivity/useServiceActivityCsvColumns.ts'
import PendingClientActionCell from '@/components/table/cells/PendingClientActionCell.tsx'
import { pendingClientActionSortFn } from '@/components/table/sort/pendingClientActionSort.ts'

export function AssetServiceActivityTab(): React.JSX.Element {
  const { assetId } = useOutletContext<AssetDetailContext>()
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  const serviceActivityQuery = useAssetServiceActivityQuery(assetId, true)
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<ServiceActivity>()

  const columns = useMemo(
    (): ColumnDef<ServiceActivity>[] => [
      ...((serviceCloudIntegrationEnabled
        ? [
            textColumn({
              key: 'caseNumber',
              header: 'Case Number',
            }),
            {
              header: 'Work Orders',
              accessorKey: 'workOrdersCount',
              enableSorting: false,
              cell: WorkOrdersCountCell,
            },
          ]
        : [
            textColumn({
              key: 'id',
              header: 'Ticket ID',
            }),
          ]) as ColumnDef<ServiceActivity>[]),
      {
        accessorKey: 'createdAt',
        enableSorting: true,
        header: 'Creation Date',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
        enableSorting: true,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority?.toString()
        ),
        filterFn: facetFilterFn,
        cell: TicketPriorityCell,
      },
      textColumn({
        key: 'subject',
        header: 'Subject',
      }),
      {
        accessorKey: 'creationReason',
        header: 'Creation Reason',
        enableSorting: false,
        filterFn: facetFilterFn,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        cell: ServiceActivityStatusCell,
        filterFn: facetFilterFn,
      },
      ...((serviceCloudIntegrationEnabled
        ? []
        : [
            {
              accessorKey: 'subStatus',
              header: 'Progress',
              enableSorting: false,
              cell: TicketStatusCell,
              filterFn: facetFilterFn,
            },
            {
              accessorKey: 'scheduleDate',
              enableSorting: true,
              header: 'Schedule Date',
              filterFn: dateRangeFilterFn,
              cell: DateCell,
            },
          ]) as ColumnDef<ServiceActivity>[]),
      {
        accessorKey: 'updatedAt',
        enableSorting: true,
        header: 'Last Updated',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      ...((serviceCloudIntegrationEnabled
        ? [
            {
              id: 'closedAt',
              accessorFn: row => row.closedAt ?? undefined,
              enableSorting: true,
              header: 'Closed Date',
              filterFn: dateRangeFilterFn,
              cell: DateTimeCell,
            },
            {
              id: 'pendingClientAction',
              accessorKey: 'pendingClientAction',
              enableSorting: true,
              sortingFn: pendingClientActionSortFn,
              header: 'Pending Action',
              filterFn: facetFilterFn,
              accessorFn: serviceActivityPendingClientActionFilterDataAccessor,
              cell: PendingClientActionCell,
            },
          ]
        : []) as ColumnDef<ServiceActivity>[]),
    ],
    [serviceCloudIntegrationEnabled]
  )
  const exportCsvColumns = useServiceActivityCsvColumns({
    includeClosedDate: true,
  })

  const filterDefs = useMemo<FilterDrawerFilterDef<ServiceActivity>[]>(
    () => [
      creationDateFilter('createdAt'),
      priorityFilter(
        'priority',
        rowData => rowData.priority?.toString() ?? null
      ),
      creationReasonFilter(
        'creationReason',
        (rowData: ServiceActivity) => rowData.creationReason
      ),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      ),
      ...((serviceCloudIntegrationEnabled
        ? []
        : [
            subStatusFilter(
              'subStatus',
              (rowData: ServiceActivity) => rowData.subStatus
            ),
            scheduledDateFilter('scheduleDate'),
          ]) as FilterDrawerFilterDef<ServiceActivity>[]),
      updatedDateFilter('updatedAt'),
      ...((serviceCloudIntegrationEnabled
        ? [
            closedDateFilter('closedAt'),
            pendingClientActionFilter(
              'pendingClientAction',
              serviceActivityPendingClientActionFilterDataAccessor
            ),
          ]
        : []) as FilterDrawerFilterDef<ServiceActivity>[]),
    ],
    [serviceCloudIntegrationEnabled]
  )

  const rows = serviceActivityQuery.data?.data
  const sortedRows = useMemo<ServiceActivity[] | undefined>(
    () => rows?.sort(assetServiceActivityCustomSort),
    [rows]
  )
  const tableModel = useTable(sortedRows, columns)
  const [tableFilterState, tableFilterStateActions] =
    useTableFilterState(tableModel)
  const enableFilterControls = !!rows?.length

  const handleClickRow = (row: Row<ServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }

  return (
    <>
      <h2 className="tw-mb-6 tw-text-xl tw-font-bold tw-text-gray-900">
        Service Activity
      </h2>
      <div className="tw-mb-4 tw-flex tw-w-full tw-flex-row tw-justify-between tw-gap-4 tw-text-base tw-font-normal tw-text-gray-500">
        <div className="tw-grow">
          <SearchInput
            value={tableModel.globalFilter}
            onChange={value => {
              tableModel.setGlobalFilter(String(value))
            }}
            placeholder="Search"
            disabled={!enableFilterControls}
          />
        </div>
        <div className="tw-space-x-4">
          <FiltersDrawerButton
            filterDefs={filterDefs}
            rowData={rows}
            appliedFilters={tableFilterState.activeFilters}
            disabled={!enableFilterControls}
            onApplyFilters={tableFilterStateActions.applyFilters}
          />
          {assetMetadataQuery.data && (
            <Tooltip
              content={`Export current table\nview, including any\napplied filters`}
            >
              <ExportCsvButton
                fileName={`${assetMetadataQuery.data.assetName}_service_activity`}
                columns={exportCsvColumns}
                rows={() =>
                  tableModel
                    .getSortedRowModel()
                    .rows.map(r => formatCSVRow(r.original))
                }
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="tw-mb-6 tw-flex tw-w-full tw-flex-row tw-items-end tw-justify-end tw-gap-4">
        {tableFilterState.hasActiveFilters && (
          <div className="tw-grow">
            <ActiveFilters
              filterDefs={filterDefs}
              appliedFilters={tableFilterState.activeFilters}
              onClearFilter={tableFilterStateActions.clearFilter}
              onClearAll={tableFilterStateActions.clearAllFilters}
            />
          </div>
        )}
        <div className="tw-text-gray-500">
          Tickets:&nbsp;{tableModel.getFilteredRowModel().rows.length}
        </div>
      </div>
      <div className="tw-overflow-auto">
        {serviceActivityQuery.isFetching && <LoadingPage />}
        {serviceActivityQuery.isFetched && (
          <Table
            className="tw-shrink"
            model={tableModel}
            rowClassName={rowClassName}
            onRowClick={handleClickRow}
            loading={serviceActivityQuery.isFetching}
            error={serviceActivityQuery.isError}
            noDataMessage={
              <ServiceActivityTableNoDataMessage tableModel={tableModel} />
            }
          />
        )}
        {selectedServiceActivity && (
          <ServiceActivityModal
            serviceActivity={selectedServiceActivity}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </>
  )
}

function rowClassName(row: Row<ServiceActivity>) {
  return twMerge(
    isServiceActivityClosed(row.original) && 'tw-bg-gray-50',
    'hover:tw-bg-gray-50'
  )
}

/**
 * The desired sort order for the initial sort of the asset service activity table cannot be defined as a
 * simple sort by columns. Additionally, some of the sort criteria are not desired to be sortable columns
 * in the table.
 */
function assetServiceActivityCustomSort(
  a: ServiceActivity,
  b: ServiceActivity
) {
  // Closed tickets drop below active tickets
  const isAClosed = isServiceActivityClosed(a)
  const isBClosed = isServiceActivityClosed(b)
  if (isAClosed && !isBClosed) return 1
  if (!isAClosed && isBClosed) return -1
  // If both tickets are closed, sort by status, then by last updated
  if (isAClosed && isBClosed) {
    const statusCompare = serviceActivityStatusSort(a.status, b.status)
    if (statusCompare !== 0) return statusCompare
    const aUpdatedAt = moment(a.updatedAt)
    if (aUpdatedAt.isBefore(b.updatedAt)) return 1
    if (aUpdatedAt.isAfter(b.updatedAt)) return -1
    return 0
  }
  // If both tickets are active, sort by priority
  return ticketPrioritySort(a.priority ?? '', b.priority ?? '')
}
