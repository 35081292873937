import { ReactNode } from 'react'

type TwoValueTableElementProps = {
  primary: string | ReactNode
  secondary: string | ReactNode
}
export default function TwoValueTableElement({
  primary,
  secondary,
}: Readonly<TwoValueTableElementProps>) {
  return (
    <div>
      <div>{primary}</div>
      <div className="tw-text-xs tw-font-normal tw-text-gray-500">
        {secondary}
      </div>
    </div>
  )
}
