import { filterGroupState, FilterGroupState } from './useFilterGroupState.ts'
import { TableModel } from '../useTable.tsx'
import { useMemo } from 'react'
import { FilterData, FilterValue } from './filterData.ts'

export type TableFilterState = {
  activeFilters: FilterGroupState
  hasActiveFilters: boolean
}

export type TableFilterStateActions = {
  applyFilters: (newFilters: FilterGroupState) => void
  clearFilter: (columnId: string) => void
  clearAllFilters: () => void
}

/**
 * Provide state/actions to interact with a table's column filter settings for UI components external to
 * the table.
 */
export function useTableFilterState<T>(
  tableModel: TableModel<T>
): [TableFilterState, TableFilterStateActions] {
  const columnIds = tableModel.getAllColumns().map(c => c.id)

  const activeFilters = useMemo(() => {
    const map = new Map<string, FilterData>()
    columnIds.forEach(columnId => {
      const filterValue = tableModel
        .getColumn(columnId)
        ?.getFilterValue() as FilterValue
      if (filterValue) {
        map.set(columnId, filterValue)
      }
    })
    return filterGroupState(map)
  }, [columnIds, tableModel])

  const clearFilter = (columnId: string) => {
    tableModel.getColumn(columnId)?.setFilterValue(undefined)
  }
  return [
    {
      activeFilters,
      hasActiveFilters: activeFilters.map.size > 0,
    },
    {
      applyFilters: (newFilters: FilterGroupState) => {
        columnIds.forEach(columnnId => {
          tableModel
            .getColumn(columnnId)
            ?.setFilterValue(newFilters.map.get(columnnId))
        })
      },
      clearFilter: clearFilter,
      clearAllFilters: () => {
        activeFilters.map.forEach((_, key) => {
          clearFilter(key)
        })
      },
    },
  ]
}
