import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import ServiceActivityPageResidential from '@/pages/serviceActivity/residential/ServiceActivityPageResidential.tsx'
import { ServiceActivityPageCommercial } from '@/pages/serviceActivity/commercial/ServiceActivityPageCommercial.tsx'

export default function ServiceActivityPageWrapper() {
  const { isResidentialClient } = useFeatureFlags()

  if (isResidentialClient) {
    return <ServiceActivityPageResidential />
  }

  return <ServiceActivityPageCommercial />
}
