import React, { useMemo, useState } from 'react'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import Table from '@/components/core/table/Table.tsx'
import { TicketSummaryCell } from '@/components/table/cells/TicketSummaryCell.tsx'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import { DispatchApprovalModal } from '@/components/modals/dispatch/DispatchApprovalModal.tsx'
import { Portal } from '@headlessui/react'
import Alert from '@/components/core/alerts/Alert.tsx'
import iconCheck from '@iconify/icons-material-symbols/check-circle'
import { useRecordDispatchAction } from '@/api/ApiQueries.ts'
import { DispatchApprovalRow } from '@/generated'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'

function SelectActionButtonCell(
  ctx: Readonly<CellContext<DispatchApprovalRow, unknown>>
): React.JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showToast, setShowToast] = useState<boolean>(false)
  const recordDispatchAction = useRecordDispatchAction()
  const enableToast = () => {
    setShowToast(true)
    setTimeout(() => {
      setShowToast(false)
    }, 4000)
  }
  const ticketId = ctx.row.original.ticket.id
  return (
    <>
      <div className="tw-whitespace-nowrap">
        <Button
          color="outline"
          onClick={() => {
            setShowModal(true)
          }}
        >
          Select action
        </Button>
      </div>
      {showModal && (
        <Portal>
          <DispatchApprovalModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            onSubmit={option => {
              recordDispatchAction.mutate({
                ticketId: ticketId,
                action: option,
              })
              setShowModal(false)
              enableToast()
            }}
          />
        </Portal>
      )}
      {showToast && (
        <Portal>
          <div className="tw-fixed tw-top-28 tw-z-0 tw-flex tw-w-full tw-justify-center">
            <Alert
              color="green"
              className="tw-pr-20 tw-font-bold"
              icon={iconCheck}
              title="Successfully actioned. We've sent a confirmation email and will be in touch with next steps."
            />
          </div>
        </Portal>
      )}
    </>
  )
}

export type ApprovalTasksTableProps = {
  readonly tasks?: DispatchApprovalRow[]
  readonly error: boolean
  readonly loading: boolean
  readonly showAssetManagerColumn?: boolean
}

export function ApprovalTasksTable({
  tasks,
  error,
  loading,
  showAssetManagerColumn,
}: ApprovalTasksTableProps): React.JSX.Element {
  const columns = useMemo((): ColumnDef<DispatchApprovalRow>[] => {
    const columnDefs: ColumnDef<DispatchApprovalRow>[] = []
    if (showAssetManagerColumn) {
      columnDefs.push(
        textColumn({
          key: 'assetManagerName',
          header: 'Asset Manager',
          mode: 'filterable-facets',
        })
      )
    }
    columnDefs.push(
      textColumn({
        key: 'assetName',
        header: 'Asset',
        mode: 'filterable-search',
      }),
      textColumn({
        key: 'ticket.id',
        header: 'Ticket ID',
      }),
      textColumn({
        key: 'ticket.priority',
        header: 'Priority',
        mode: 'sortable',
        sortingFn: ticketPriorityTableRowSort(
          row => row.original.ticket.priority
        ),
        cell: TicketPriorityCell,
      }),
      textColumn({
        key: 'ticket.subject',
        header: 'Subject',
      }),
      textColumn({
        key: 'ticket.creationReason',
        header: 'Ticket Creation Reason',
      }),
      {
        id: 'ticket.createdAt',
        accessorKey: 'ticket.createdAt',
        enableSorting: false,
        header: 'Creation Date',
        cell: DateTimeCell,
      },
      textColumn({
        key: 'ticket.estimatedDispatchCost',
        header: 'Estimated Dispatch Cost',
      }),
      {
        id: 'ticket.summary',
        accessorKey: 'ticket.summary',
        enableSorting: false,
        header: 'Case Summary',
        cell: TicketSummaryCell(ctx => ctx.row.original.ticket.subject),
      },
      {
        enableSorting: false,
        id: 'select-action-button',
        cell: SelectActionButtonCell,
      }
    )
    return columnDefs
  }, [showAssetManagerColumn])

  const tableModel = useTable(tasks, columns, {
    initialSort: [{ id: 'ticket.priority', desc: false }],
  })

  // TODO: get "no data" message mockup
  return (
    <Table
      className="tw-shrink"
      model={tableModel}
      loading={loading}
      error={error}
      noDataMessage={
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
          <div className="tw-text-xl">No tasks</div>
          <div className="tw-text-base tw-text-gray-400">
            You&apos;re all done!
          </div>
        </div>
      }
    />
  )
}
