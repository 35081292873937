import React, { forwardRef } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

// Helper type for representing state of a potentially indeterminate checkbox
export type CheckboxState = {
  checked: boolean
  indeterminate: boolean
}

interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange'
  > {
  indeterminate?: boolean
  onChange?: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void
}

export default forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { className, indeterminate, onChange, ...props }: Readonly<CheckboxProps>,
  ref
): React.JSX.Element {
  return (
    <input
      ref={(e: HTMLInputElement | null) => {
        if (e && typeof indeterminate === 'boolean') {
          e.indeterminate = !props.checked && indeterminate
        }
        return ref
      }}
      type="checkbox"
      className={twMerge(
        'tw-m-0 tw-rounded tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-p-0 tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500',
        className
      )}
      onChange={e => {
        onChange?.(e.target.checked, e)
      }}
      {...props}
    />
  )
})
