import { Row, RowData } from '@tanstack/react-table'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { FilterDataBase } from './filterData.ts'
import { useState } from 'react'

export type FacetFilterValue = Maybe<FacetFilterData>

export interface FacetFilterData extends FilterDataBase {
  type: 'faceted'
  selectedFacets: Set<string>
}

export type FacetFilterDataActions = {
  toggle: (facet: string) => void
  clear: () => void
}

export function useFacetFilterData(
  selectedFacets?: Set<string>
): [FacetFilterData, FacetFilterDataActions] {
  const [selected, setSelected] = useState<Set<string>>(new Set(selectedFacets))

  return [
    facetFilterData(selected),
    {
      toggle: (facet: string) => {
        if (selected.has(facet)) selected.delete(facet)
        else selected.add(facet)

        setSelected(new Set(selected))
      },
      clear: () => {
        setSelected(new Set())
      },
    },
  ]
}

export function facetFilterData(selectedFacets: Set<string>): FacetFilterData {
  return {
    type: 'faceted',
    isActive: selectedFacets.size > 0,
    text: facetFilterText(selectedFacets),
    selectedFacets: new Set(selectedFacets),
  }
}

function facetFilterText(selectedFacets: Set<string>) {
  if (selectedFacets.size > 0) {
    return Array.from(selectedFacets)
      .sort((a: string, b: string) => a.localeCompare(b.toString()))
      .join(', ')
  }
  return undefined
}

/**
 * A filter function that checks if the value of a cell exists within
 * the columns filterState.
 * @param row the table row
 * @param columnId the id of the column within the row
 * @param filterValue the current filter state
 */
export function facetFilterFn<TData extends RowData>(
  row: Row<TData>,
  columnId: string,
  filterValue: FacetFilterValue
): boolean {
  if (filterValue?.isActive) {
    const value = row.getValue<Maybe<string>>(columnId)
    return !!value && filterValue.selectedFacets.has(value)
  } else {
    return true
  }
}
