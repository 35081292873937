/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { DefaultNumberFormat, formatNumber } from '@/helpers/NumberFormats.ts'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { AggregationFn } from './aggregation.ts'

export type TotalDef<T extends Record<string, any>> = {
  key: keyof T
  agg?: AggregationFn<T>
  format?: (value: Maybe<number>) => Maybe<string>
  cell?: (rows: T[]) => React.ReactElement
  className?: string
  tooltip?: string
}

export type TotalCellModel = {
  value: Maybe<string>
  className?: string
  tooltip?: string
}

export type TotalsRowModel<T extends Record<string, any>> = {
  cells: Map<keyof T, TotalCellModel>
}

/**
 * Computes and memoizes totals given an array of total
 * definitions and the rows to apply them to
 * @param rows the rows to compute the totals for
 * @param totals the definitions of the totals to be computed
 */
export function useTotals<T extends Record<string, any>>(
  rows: T[],
  totals: TotalDef<T>[]
): TotalsRowModel<T> {
  return useMemo(() => {
    const computeTotal = (total: TotalDef<T>): Maybe<string> => {
      const totalValue = total.agg?.(total.key, rows)
      return total.format
        ? total.format(totalValue)
        : formatNumber(totalValue, DefaultNumberFormat())
    }

    return {
      cells: new Map<keyof T, TotalCellModel>(
        totals.map(
          total =>
            [
              total.key,
              {
                value: computeTotal(total),
                className: total.className,
                tooltip: total.tooltip,
              },
            ] as const
        )
      ),
    }
  }, [totals, rows])
}
