import { autoPlacement, offset, useFloating } from '@floating-ui/react'
import { Portal } from '@headlessui/react'
import React, { useMemo, useState } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { DATE_INPUT_FORMAT, parseIsoDate } from '@/helpers/MomentHelpers.ts'
import Button from '@/components/core/buttons/Button.tsx'
import DateRangeSelector, { DateRangePreset } from './DateRangeSelector.tsx'
import { useDateRangeSelectorState } from './useDateRangeSelectorState.tsx'

export interface DateRangeFilterProps {
  currentMonthPosition?: 'left' | 'right'
  format?: string
  placeholder?: string
  presets?: DateRangePreset[]
  isoCurrentMonth?: string
  isoStartDate?: string
  isoEndDate?: string
  isoMinDate?: string
  isoMaxDate?: string
  onApply?: (isoStartDate: string, isoEndDate: string) => void
}

export default function DateRangeFilter({
  currentMonthPosition = 'left',
  placeholder = 'Select period',
  format = DATE_INPUT_FORMAT,
  ...props
}: Readonly<DateRangeFilterProps>): React.JSX.Element {
  const [showDateRangeSelector, setShowDateRangeSelector] = useState(false)
  const filterStartDate = useMemo(
    () => parseIsoDate(props.isoStartDate ?? null) ?? null,
    [props.isoStartDate]
  )
  const filterEndDate = useMemo(
    () => parseIsoDate(props.isoEndDate ?? null) ?? null,
    [props.isoEndDate]
  )

  const [selectorState, selectorActions] = useDateRangeSelectorState({
    currentMonthPosition: currentMonthPosition,
    isoCurrentMonth: props.isoCurrentMonth,
    isoStartDate: props.isoStartDate,
    isoEndDate: props.isoEndDate,
    isoMinDate: props.isoMinDate,
    isoMaxDate: props.isoMaxDate,
  })

  const float = useFloating<HTMLDivElement>({
    middleware: [offset(8), autoPlacement({ alignment: 'start' })],
  })

  function toggleDateRangeSelector() {
    // reset selector state before showing it
    if (!showDateRangeSelector) {
      selectorActions.setSelectedDateRange(filterStartDate, filterEndDate)
    }
    setShowDateRangeSelector(!showDateRangeSelector)
  }

  function hideDateRangeSelector() {
    setShowDateRangeSelector(false)
  }

  function applySelectionHandler() {
    setShowDateRangeSelector(false)
    if (props.onApply && selectorState.startDate && selectorState.endDate)
      props.onApply(
        selectorState.startDate.format('YYYY-MM-DD'),
        selectorState.endDate.format('YYYY-MM-DD')
      )
  }

  function formatSelection() {
    if (filterStartDate && filterEndDate)
      return `${filterStartDate.format(format)} - ${filterEndDate.format(format)}`
    return ''
  }

  return (
    <>
      <button
        ref={float.refs.setReference}
        className={twMerge(`tw-relative tw-inline-block tw-max-w-sm`)}
        onClick={toggleDateRangeSelector}
      >
        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
          <svg
            aria-hidden="true"
            className="tw-size-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            />
          </svg>
        </div>
        <input
          readOnly
          type="text"
          autoComplete="off"
          className={twMerge(
            'tw-block tw-w-full tw-cursor-pointer tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-50 tw-py-2 tw-pl-10 tw-pr-2 tw-text-sm tw-font-normal tw-text-gray-900 focus:tw-border-blue-500 focus:tw-ring-blue-500',
            showDateRangeSelector ? 'tw-text-gray-400' : 'tw-text-gray-500'
          )}
          placeholder={placeholder}
          value={formatSelection()}
        />
      </button>
      {showDateRangeSelector && (
        <Portal>
          <div
            ref={float.refs.setFloating}
            style={float.floatingStyles}
            className="tw-z-popover"
          >
            <div className="tw-shrink-0 tw-rounded-lg tw-border-b tw-border-gray-400 tw-bg-white tw-p-2 tw-drop-shadow-md">
              <DateRangeSelector
                presets={props.presets}
                state={selectorState}
                actions={selectorActions}
              />
              <div className="tw-flex tw-flex-row tw-justify-center tw-gap-4 tw-pb-2 tw-pt-4">
                <Button color="alternative" onClick={hideDateRangeSelector}>
                  Cancel
                </Button>
                <Button
                  disabled={selectorState.phase !== 'completed'}
                  onClick={applySelectionHandler}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}
