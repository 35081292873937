import { Column } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import SearchInput from './SearchInput.tsx'

export interface SearchFilterPopoverProps<T> {
  column: Column<T>
  onClose: () => void
}

export default function SearchFilter<T>(
  props: Readonly<SearchFilterPopoverProps<T>>
) {
  const currentFilterValue = props.column.getFilterValue() as string | undefined

  // NOTE: using useState here rather than useRef because the ref is used in an effect
  // that needs to run after rendering occurs and the ref gets updated
  const [initialFocusRef, setInitialFocusRef] =
    useState<HTMLInputElement | null>(null)

  useEffect(() => {
    if (initialFocusRef) {
      initialFocusRef.focus()
    }
  }, [initialFocusRef])

  function clear() {
    if (initialFocusRef) initialFocusRef.value = ''
  }

  function apply() {
    props.column.setFilterValue(initialFocusRef?.value ?? '')
    props.onClose()
  }

  return (
    <div className="tw-cursor-pointer tw-select-none tw-divide-y tw-divide-gray-100 tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-text-gray-500 tw-shadow">
      <div className="tw-p-3">
        <SearchInput
          ref={setInitialFocusRef}
          value={currentFilterValue ?? ''}
          placeholder="Search"
        />
      </div>
      <div
        className={`tw-flex tw-flex-row tw-justify-around tw-gap-2 tw-border-t-2 tw-border-solid tw-border-t-gray-500 tw-p-2`}
      >
        <button
          onClick={clear}
          className={`tw-border-0 tw-bg-white tw-text-base tw-text-gray-500 tw-underline`}
        >
          Clear
        </button>
        <button
          onClick={apply}
          className={`tw-border-0 tw-bg-white tw-text-base tw-text-blue-700 tw-underline`}
        >
          Apply
        </button>
      </div>
    </div>
  )
}
