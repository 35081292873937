import useServiceActivityTableColumns from '@/pages/serviceActivity/residential/tableComponents/useServiceActivityTableColumns.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import React, { useMemo } from 'react'
import { useTableFilterState } from '@/components/core/table/filters/useTableFitlerState.ts'
import { ServiceActivityTableContext } from '@/pages/serviceActivity/residential/tableComponents/context/ServiceActivityTableContext.tsx'
import useServiceActivityQueryResidential from '@/pages/serviceActivity/residential/useServiceActivityQueryResidential.tsx'

type Props = {
  children: React.ReactNode
}
export default function ServiceActivityTableProvider({
  children,
}: Readonly<Props>) {
  const { data: rows } = useServiceActivityQueryResidential()
  const columns = useServiceActivityTableColumns()
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
  })

  const [tableFilterState, tableFilterStateActions] =
    useTableFilterState(tableModel)

  const value = useMemo(
    () => ({
      tableModel,
      rows: rows ?? [],
      tableFilterState,
      tableFilterStateActions,
    }),
    [tableModel, rows, tableFilterState, tableFilterStateActions]
  )
  return (
    <ServiceActivityTableContext.Provider value={value}>
      {children}
    </ServiceActivityTableContext.Provider>
  )
}
