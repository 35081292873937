import { useWorkOrdersContext } from './providers/WorkOrdersProvider.tsx'
import WorkOrderTabGroup from './work-order-tab-group/WorkOrderTabGroup.tsx'
import HorizontalPropertyItem from '../HorizontalPropertyItem.tsx'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'

export default function WorkOrderContent() {
  const {
    selected: { subject, status, subStatus, createdAt, updatedAt },
  } = useWorkOrdersContext()

  return (
    <div className="tw-px-6 tw-py-10">
      <div className="tw-mb-6 tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-items-start tw-justify-between">
          <h3 className="tw-text-2xl tw-font-bold tw-text-gray-700">
            {subject}
          </h3>
        </div>
        <HorizontalPropertyItem label="Status">
          {status}
          {subStatus ? `: ${subStatus}` : ''}
        </HorizontalPropertyItem>

        <HorizontalPropertyItem label="Created Date">
          {DateTimeFormat.format(new Date(createdAt))}
        </HorizontalPropertyItem>

        <HorizontalPropertyItem label="Last Updated">
          {DateTimeFormat.format(new Date(updatedAt))}
        </HorizontalPropertyItem>
      </div>

      <WorkOrderTabGroup />
    </div>
  )
}
