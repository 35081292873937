import { Moment } from 'moment'
import React, { PropsWithChildren } from 'react'
import moment from 'moment/moment'
import { AxisSetExtremesEventObject, Dictionary } from 'highcharts'
import { XAxis } from 'react-jsx-highstock'
import SetAxisZoomDates from '@/components/core/graphs/timeseries/SetAxisZoomDates.tsx'

/* https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats */
const X_AXIS_MARKER_DATE_FORMATS: Dictionary<string> = {
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%b %d',
  week: '%b %d',
  month: '%b %Y',
  year: '%b %Y',
}

export interface TimeSeriesXAxisProps {
  minDate: Moment
  maxDate: Moment
  selectedMinDate?: Moment
  selectedMaxDate?: Moment
  onSelectedRangeUpdate?: (startDate: Moment, endDate: Moment) => void
}

export default function TimeSeriesXAxis({
  minDate,
  maxDate,
  selectedMinDate,
  selectedMaxDate,
  onSelectedRangeUpdate,
  children,
}: Readonly<PropsWithChildren<TimeSeriesXAxisProps>>): React.JSX.Element {
  const onGraphZoomUpdate = (e: AxisSetExtremesEventObject) => {
    const { min, max } = e
    if (onSelectedRangeUpdate) {
      onSelectedRangeUpdate(moment(min), moment(max))
    }
  }

  return (
    <XAxis
      type="datetime"
      min={minDate.valueOf()}
      max={maxDate.valueOf()}
      dateTimeLabelFormats={X_AXIS_MARKER_DATE_FORMATS}
      events={{ afterSetExtremes: onGraphZoomUpdate }}
    >
      <SetAxisZoomDates
        zoomMinDate={selectedMinDate}
        zoomMaxDate={selectedMaxDate}
      />
      {children}
    </XAxis>
  )
}
