import { Drawer } from '@/components/core/drawers/Drawer.tsx'
import React, { useState } from 'react'
import Button from '@/components/core/buttons/Button.tsx'
import filterListIcon from '@iconify/icons-material-symbols/filter-list'
import { Portal } from '@headlessui/react'
import { FilterType } from '@/components/core/table/filters/filterData.ts'
import { RowData } from '@tanstack/table-core'
import { DateRangePreset } from '@/components/core/datepickers/DateRangeSelector.tsx'
import { FilterDrawerDateRangeSection } from './FilterDrawerDateRangeSection.tsx'
import { FilterDrawerFacetSection } from './FilterDrawerFacetSection.tsx'
import { FacetFilterValue } from '@/components/core/table/filters/facetFilterFn.ts'
import { DateRangeFilterValue } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import {
  FilterGroupState,
  useFilterGroupState,
} from '@/components/core/table/filters/useFilterGroupState.ts'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'

export interface FilterDrawerFilterDefBase<T> {
  type: FilterType
  key: NestedKeyOf<T>
  header: string
}

export interface FilterDrawerFacetFilterDef<T>
  extends FilterDrawerFilterDefBase<T> {
  type: 'faceted'
  rowDataAccessor: (rowData: T) => string | null
  sortingFn?: (a: string, b: string) => number
}

export interface FilterDrawerDateRangeFilterDef<T>
  extends FilterDrawerFilterDefBase<T> {
  type: 'date-range'
  presets: DateRangePreset[]
}

export type FilterDrawerFilterDef<T> =
  | FilterDrawerFacetFilterDef<T>
  | FilterDrawerDateRangeFilterDef<T>

interface FiltersDrawerButtonProps<TData extends RowData> {
  readonly filterDefs: Array<FilterDrawerFilterDef<TData>>
  readonly rowData: Array<TData> | undefined
  readonly appliedFilters: FilterGroupState
  readonly onApplyFilters: (newFilters: FilterGroupState) => void
  readonly disabled?: boolean
}

export function FiltersDrawerButton<T>({
  filterDefs,
  rowData,
  appliedFilters,
  disabled,
  onApplyFilters,
}: FiltersDrawerButtonProps<T>): React.JSX.Element {
  const [showDrawer, setShowDrawer] = useState(false)

  function onClose() {
    setShowDrawer(false)
  }

  return (
    <>
      <Button
        size="sm"
        color="light"
        icon={filterListIcon}
        disabled={!!disabled}
        onClick={() => {
          setShowDrawer(true)
        }}
      >
        Filters
      </Button>
      <Portal>
        <Drawer show={showDrawer} onClose={onClose} data-testid="filter-drawer">
          <FilterDrawerContent
            filterDefs={filterDefs}
            rowData={rowData}
            appliedFilters={appliedFilters}
            onApplyFilters={onApplyFilters}
            onClose={onClose}
          />
        </Drawer>
      </Portal>
    </>
  )
}

interface FilterDrawerProps<TData extends RowData> {
  readonly filterDefs: Array<FilterDrawerFilterDef<TData>>
  readonly rowData: Array<TData> | undefined
  readonly appliedFilters: FilterGroupState
  readonly onApplyFilters: (newFilters: FilterGroupState) => void
  readonly onClose: () => void
}

function FilterDrawerContent<T>({
  filterDefs,
  rowData,
  appliedFilters,
  onApplyFilters,
  onClose,
}: FilterDrawerProps<T>) {
  const [filtersState, filtersStateActions] = useFilterGroupState(
    appliedFilters.map
  )

  return (
    <>
      <Drawer.Header onClose={onClose}>Filter Tickets</Drawer.Header>
      <Drawer.Body>
        {filterDefs.map(filter => {
          if (filter.type === 'faceted') {
            return (
              <FilterDrawerFacetSection
                key={filter.key}
                filterDef={filter}
                rowData={rowData}
                filterValue={
                  filtersState.map.get(filter.key) as FacetFilterValue
                }
                onStateChange={state => {
                  filtersStateActions.updateFilter(filter.key, state)
                }}
              />
            )
          }

          return (
            <FilterDrawerDateRangeSection
              key={filter.key}
              filterDef={filter}
              filterValue={
                filtersState.map.get(filter.key) as DateRangeFilterValue
              }
              onStateChange={state => {
                filtersStateActions.updateFilter(filter.key, state)
              }}
            />
          )
        })}
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          block={true}
          color="light"
          onClick={filtersStateActions.clearAllFilters}
        >
          Clear all
        </Button>
        <Button
          block={true}
          onClick={() => {
            onApplyFilters(filtersState)
            onClose()
          }}
        >
          Apply filters
        </Button>
      </Drawer.Footer>
    </>
  )
}
