import { ComponentProps, PropsWithChildren } from 'react'
import { twJoin } from 'tailwind-merge'
import { IconifyIcon } from '@iconify/react'
import { Icon } from '@iconify/react/offline'

const ButtonSize = {
  xs: `tw-px-3 tw-py-2 tw-text-xs`,
  sm: `tw-px-3 tw-py-2 tw-text-sm`,
  default: `tw-px-5 tw-py-2.5 tw-text-sm`,
  lg: `tw-px-5 tw-py-3 tw-text-base`,
  xl: `tw-px-6 tw-py-3.5 tw-text-base`,
}

const IconSize = {
  xs: 12,
  sm: 16,
  default: 16,
  lg: 16,
  xl: 28,
}

const ButtonAlign = {
  default: '',
  left: 'tw-text-left',
}

const ButtonColor = {
  default: `tw-text-white tw-border-blue-700 tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-blue-300 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-800 disabled:tw-text-white disabled:tw-bg-blue-300 disabled:tw-border-blue-300`,
  dark: `tw-text-white tw-bg-gray-800 hover:tw-bg-gray-900 focus:tw-ring-gray-300 dark:tw-bg-gray-800 dark:tw-border-gray-700 dark:hover:tw-bg-gray-700 dark:focus:tw-ring-gray-700`,
  light: `tw-text-gray-900 tw-bg-white tw-border-gray-200 hover:tw-bg-gray-100 focus:tw-ring-gray-200 dark:tw-bg-gray-800 dark:tw-text-gray-400 dark:tw-border-gray-600 dark:hover:tw-text-white dark:hover:tw-bg-gray-700 dark:focus:tw-ring-gray-700 disabled:tw-text-grey-400 disabled:tw-bg-white disabled:tw-border-grey-200`,
  lightOutline: `tw-text-gray-900 tw-bg-white tw-border-blue-600 hover:tw-bg-gray-100 focus:tw-ring-gray-200 hover:tw-text-gray-600 dark:tw-bg-gray-800 dark:tw-text-gray-400 dark:hover:tw-bg-gray-700 disabled:tw-text-grey-400 disabled:tw-bg-white disabled:tw-border-grey-200`,
  green: `tw-text-white tw-bg-green-700 hover:tw-bg-green-800 focus:tw-ring-green-300 dark:tw-bg-green-600 dark:hover:tw-bg-green-700 dark:focus:tw-ring-green-800`,
  red: `tw-text-white tw-border-red-700 tw-bg-red-700 hover:tw-bg-red-800 focus:tw-ring-red-300 dark:tw-bg-red-600 dark:hover:tw-bg-red-700 dark:focus:tw-ring-red-900`,
  yellow: `tw-text-white tw-border-yellow-400 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-yellow-300 dark:focus:tw-ring-yellow-900`,
  alternative: `tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-ring-4 focus:tw-ring-gray-200 dark:focus:tw-ring-gray-700 dark:tw-bg-gray-800 dark:tw-text-gray-400 dark:tw-border-gray-600 dark:hover:tw-text-white dark:hover:tw-bg-gray-700 disabled:tw-text-gray-400 disabled:tw-bg-white tw-border disabled:tw-border-gray-200`,
  outline: `tw-text-blue-700 hover:tw-text-white tw-border tw-border-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center tw-mr-2 tw-mb-2 dark:tw-border-blue-500 dark:tw-text-blue-500 dark:hover:tw-text-white dark:hover:tw-bg-blue-500 dark:focus:tw-ring-blue-800`,
}

export interface ButtonProps
  extends PropsWithChildren<Omit<ComponentProps<'button'>, 'className'>> {
  readonly size?: keyof typeof ButtonSize
  readonly color?: keyof typeof ButtonColor
  readonly align?: keyof typeof ButtonAlign
  readonly icon?: IconifyIcon
  readonly block?: boolean
}

export default function Button({
  size,
  color,
  align,
  block,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      className={twJoin(
        'tw-select-none tw-rounded-lg tw-border tw-border-solid tw-font-medium focus:tw-outline-none focus:tw-ring-4',
        size ? ButtonSize[size] : ButtonSize.default,
        color ? ButtonColor[color] : ButtonColor.default,
        align ? ButtonAlign[align] : ButtonAlign.default,
        block ? 'tw-w-full' : ''
      )}
    >
      {props.icon ? (
        <div className="tw-space-x-1">
          <Icon
            icon={props.icon}
            className="tw-inline"
            width={size ? IconSize[size] : IconSize.default}
            height={size ? IconSize[size] : IconSize.default}
          />
          <span>{props.children}</span>
        </div>
      ) : (
        props.children
      )}
    </button>
  )
}
