import { Moment } from 'moment'
import { parseIsoDate } from '@/helpers/MomentHelpers.ts'

/**
 * Representation of different types of supported data points
 */
interface DataPointBase {
  type: 'time-series' | 'category'
  value: number | null
}

/**
 * Data point appropriate to use for a graph with a 'categories' X-axis
 */
export type CategoryDataPoint = DataPointBase & {
  type: 'category'
}

/**
 * Data point appropriate to use for a graph with a TimeSeriesXAxis
 */
export type TimeSeriesDataPoint = DataPointBase & {
  type: 'time-series'
  date?: Moment
}

export type SeriesDataPoint = CategoryDataPoint | TimeSeriesDataPoint

export function createTimeSeriesDataPoint(
  isoDate: string,
  value: number | null
): TimeSeriesDataPoint {
  return {
    type: 'time-series',
    date: parseIsoDate(isoDate) ?? undefined,
    value,
  }
}

export function createCategoryDataPoint(
  value: number | null
): CategoryDataPoint {
  return {
    type: 'category',
    value,
  }
}
