import { PageCard } from '@/components/cards/PageCard.tsx'
import ServiceActivityTable from '@/pages/serviceActivity/residential/tableComponents/ServiceActivityTable.tsx'
import ServiceActivityTableControls from '@/pages/serviceActivity/residential/tableComponents/ServiceActivityTableControls.tsx'
import ServiceActivityTableProvider from '@/pages/serviceActivity/residential/tableComponents/context/ServiceActivityTableProvider.tsx'
import MetadataVerificationSnapshot from '@/pages/serviceActivity/residential/snapShots/MetadataVerificationSnapshot.tsx'
import PerformanceVerificationSnapshot from '@/pages/serviceActivity/residential/snapShots/PerformanceVerificationSnapshot.tsx'
import ActiveAssetsSnapshot from '@/pages/serviceActivity/residential/snapShots/ActiveAssetsSnapshot.tsx'

export default function ServiceActivityPageResidential() {
  const client = 'EverBright'
  return (
    <div className="tw-p-8">
      <div className="tw-mb-8 tw-flex tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following views show all open service activity (tickets) for
            &nbsp;{client} assets
          </div>
        </div>
      </div>
      <div className="tw-mb-8 tw-flex tw-w-full tw-justify-between tw-gap-8">
        <div className="tw-flex-1">
          <MetadataVerificationSnapshot />
        </div>
        <div className="tw-flex-1">
          <PerformanceVerificationSnapshot />
        </div>
        <div className="tw-flex-1">
          <ActiveAssetsSnapshot />
        </div>
      </div>
      <PageCard>
        <ServiceActivityTableProvider>
          <PageCard.Header>
            <ServiceActivityTableControls />
          </PageCard.Header>
          <ServiceActivityTable />
        </ServiceActivityTableProvider>
      </PageCard>
    </div>
  )
}
