import { ComponentProps, PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface CardProps extends PropsWithChildren<ComponentProps<'div'>> {}

export function Card({ children, className, ...props }: CardProps) {
  return (
    <div
      className={twMerge(
        `tw-mb-6 tw-shrink-0 tw-rounded-lg tw-border-b tw-border-gray-400 tw-bg-white tw-p-6 tw-drop-shadow-md`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
