import { useFeatureFlags } from './FeatureFlags.tsx'

export function useDefaultRoute(): string {
  const { dispatchApprovalEnabled, portfolioOverviewEnabled } =
    useFeatureFlags()

  if (dispatchApprovalEnabled) return 'home/awaiting-approval'
  else if (portfolioOverviewEnabled) return 'portfolio'
  return '/'
}
