import { twMerge } from '@/helpers/CustomTwMerge.ts'

interface RadioButtonProps
  extends Omit<React.ComponentProps<'input'>, 'type' | 'value'> {
  value: string
}

function RadioButton({
  children,
  ...props
}: Readonly<RadioButtonProps>): React.JSX.Element {
  return (
    <div className="tw-flex tw-border-r tw-border-gray-200 last:tw-border-0">
      <input {...props} type="radio" className="tw-peer tw-hidden" />
      <label
        htmlFor={props.id}
        className={twMerge(
          `tw-px-4 tw-py-2 tw-text-sm tw-font-medium peer-checked:tw-bg-blue-100 peer-checked:tw-text-blue-600`,
          props.disabled
            ? `tw-text-gray-400`
            : `tw-cursor-pointer tw-text-gray-900 hover:tw-bg-gray-200`,
          props.className
        )}
      >
        {children}
      </label>
    </div>
  )
}

interface ToggleRadioGroupProps<TValue extends string> {
  children: React.ReactElement<RadioButtonProps>[]
  onChange?: (value: TValue) => void
}

export function ToggleRadioGroup<TValue extends string>(
  props: Readonly<ToggleRadioGroupProps<TValue>>
): React.JSX.Element {
  return (
    <div
      className="tw-inline-flex tw-rounded-md tw-border tw-border-gray-200 tw-bg-white"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e.target.value as TValue)
      }}
    >
      {props.children}
    </div>
  )
}

RadioButton.displayName = 'ToggleRadioGroup.RadioButton'
ToggleRadioGroup.RadioButton = RadioButton
