import React, { createContext, PropsWithChildren, useMemo } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'

/**
 * Context that provides a facade around Okta Auth's state and simplifies mocking for tests/stories.
 */
export type AuthContextValue = {
  isAuthenticated?: boolean
  authError?: Error
  accessToken?: string
  userName?: string
  userEmail?: string
  signInWithRedirect: () => Promise<void>
  handleLoginRedirect: () => Promise<void>
  signOut: () => Promise<boolean>
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextValue>(null!)

export function AuthProvider(
  props: Readonly<PropsWithChildren>
): React.JSX.Element {
  const { oktaAuth, authState } = useOktaAuth()
  const contextValue: AuthContextValue = useMemo(
    () => ({
      isAuthenticated: authState?.isAuthenticated,
      authError: authState?.error,
      accessToken: authState?.accessToken?.accessToken,
      userName: authState?.idToken?.claims.name,
      userEmail: authState?.idToken?.claims.email,
      signInWithRedirect: () =>
        oktaAuth.signInWithRedirect({
          originalUri: toRelativeUrl(
            window.location.href,
            window.location.origin
          ),
        }),
      handleLoginRedirect: () => oktaAuth.handleLoginRedirect(),
      signOut: () => oktaAuth.signOut(),
    }),
    [oktaAuth, authState]
  )
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}
