import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'

export function DateTimeCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const dateTime = ctx.getValue<string>()
  if (dateTime) return <>{DateTimeFormat.format(new Date(dateTime))}</>
  return <></>
}
