import type { PropsWithChildren } from 'react'

interface VerticalPropertyItemProps {
  label: string
}

export default function VerticalPropertyItem({
  label,
  children,
}: PropsWithChildren<VerticalPropertyItemProps>) {
  return (
    <div className="tw-flex tw-flex-col">
      <span className="tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900">
        {label}
      </span>
      <span className="tw-text-gray-500">{children}</span>
    </div>
  )
}
