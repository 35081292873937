import React from 'react'
import { TimeOptions } from 'highcharts'
import { Moment } from 'moment'
import {
  Chart,
  HighchartsStockChart,
  Navigator,
  YAxis,
} from 'react-jsx-highstock'
import { BoostOptions, PlotOptions } from 'highcharts/highstock'
import {
  ACTUAL_ENERGY_SERIES_CONFIG,
  ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG,
  ACTUAL_VS_EXPECTED_SERIES_CONFIG,
  EXPECTED_ENERGY_SERIES_CONFIG,
} from '@/components/graphs/types.ts'
import { GraphSeries } from '@/components/core/graphs/GraphSeries.tsx'
import HighchartsWrapper from '@/components/core/graphs/HighchartsWrapper.tsx'
import { DailyPerformanceRow } from '@/generated'
import {
  createTimeSeriesDataPoint,
  SeriesDataPoint,
} from '@/components/core/graphs/SeriesDataPoint.ts'
import TimeSeriesTooltip from '@/components/core/graphs/timeseries/TimeSeriesTooltip.tsx'
import TimeSeriesXAxis from '@/components/core/graphs/timeseries/TimeSeriesXAxis.tsx'

const BOOST_OPTIONS: BoostOptions = {
  enabled: false,
}

const PLOT_OPTIONS: PlotOptions = {
  series: {
    animation: false,
    dataGrouping: {
      enabled: false,
    },
  },
}

const TIME_OPTIONS: TimeOptions = {
  useUTC: false,
}

interface DailyPerformanceGraphProps {
  performanceRows: DailyPerformanceRow[]
  minDate: Moment
  maxDate: Moment
  selectedMinDate: Moment
  selectedMaxDate: Moment
  onSelectedRangeUpdate?: (startDate: Moment, endDate: Moment) => void
}

export function DailyPerformanceGraph({
  performanceRows,
  minDate,
  maxDate,
  selectedMinDate,
  selectedMaxDate,
  onSelectedRangeUpdate,
}: Readonly<DailyPerformanceGraphProps>): React.JSX.Element {
  return (
    <HighchartsWrapper>
      <HighchartsStockChart
        boost={BOOST_OPTIONS}
        plotOptions={PLOT_OPTIONS}
        time={TIME_OPTIONS}
      >
        <Chart animation={false} zooming={{ type: 'x' }} />
        <TimeSeriesTooltip dataGranularity="daily" />
        <TimeSeriesXAxis
          minDate={minDate}
          maxDate={maxDate}
          selectedMinDate={selectedMinDate}
          selectedMaxDate={selectedMaxDate}
          onSelectedRangeUpdate={onSelectedRangeUpdate}
        />

        <YAxis opposite={false}>
          <YAxis.Title>Energy (kWh)</YAxis.Title>
          <GraphSeries
            seriesConfig={ACTUAL_ENERGY_SERIES_CONFIG}
            data={performanceRows.map(r => createDataPoint(r, r.actualEnergy))}
            showInNavigator
          />
          <GraphSeries
            seriesConfig={EXPECTED_ENERGY_SERIES_CONFIG}
            data={performanceRows.map(r =>
              createDataPoint(r, r.expectedEnergy)
            )}
          />
        </YAxis>

        <YAxis opposite={true}>
          <YAxis.Title>(%)</YAxis.Title>
          <GraphSeries
            seriesConfig={ACTUAL_VS_EXPECTED_SERIES_CONFIG}
            data={performanceRows.map(r =>
              creatRatioDataPoint(r, r.actualVsExpectedEnergy)
            )}
          />
          <GraphSeries
            seriesConfig={ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG}
            data={performanceRows.map(r =>
              creatRatioDataPoint(r, r.actualVsExpectedEnergy7Day)
            )}
          />
        </YAxis>
        <Navigator />
      </HighchartsStockChart>
    </HighchartsWrapper>
  )
}

function createDataPoint(
  row: DailyPerformanceRow,
  value: number | null
): SeriesDataPoint {
  const roundedValue = value ? Math.round(value) : null
  return createTimeSeriesDataPoint(row.date, roundedValue)
}

function creatRatioDataPoint(
  row: DailyPerformanceRow,
  value: number | null
): SeriesDataPoint {
  const scaledValue = value === null ? null : value * 100.0
  return createDataPoint(row, scaledValue)
}
