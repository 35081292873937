import downloadIcon from '@iconify/icons-material-symbols/download'
import { stringify as toCSV } from 'csv-stringify/sync'
import { saveAs } from 'file-saver'
import moment from 'moment'
import React from 'react'
import Button from '@/components/core/buttons/Button.tsx'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'

export interface CsvColumnOption<T> {
  key: Extract<NestedKeyOf<T>, string>
  header?: string
  accessorFn?: (val: T) => string
}

export interface ExportCsvButtonProps<T> {
  readonly fileName: string
  readonly columns: CsvColumnOption<T>[]
  readonly rows: () => T[]
}

// Microsoft Excel doesn't handle timestamps with timezones very well. There is no definitive
// string format, including ISO_8601, that Excel will recognize as a timestamp instead of a general
// text string. Product would like the CSV export to have timestamps which are recognized by
// Excel so that means not including the timezone info.
export const EXCEL_CSV_TIMESTAMP_FORMAT: string = 'YYYY-MM-DD HH:mm:ss'

// Helper function to access nested properties
function getNestedValue(obj: unknown, key: string): string {
  return (
    (key.split('.').reduce<unknown>((acc, part) => {
      if (acc && typeof acc === 'object' && part in acc) {
        return acc[part as keyof typeof acc]
      }
      return undefined
    }, obj) as string) || ''
  )
}
type ProcessedRow = Record<string, string>
export function ExportCsvButton<T>(
  props: ExportCsvButtonProps<T>
): React.JSX.Element {
  return (
    <Button
      size="sm"
      color="light"
      icon={downloadIcon}
      onClick={() => {
        const csv = toCSV(
          props.rows().map(row => {
            const processedRow: ProcessedRow = {}
            props.columns.forEach(column => {
              if (column.accessorFn) {
                processedRow[column.key] = column.accessorFn(row)
              } else {
                processedRow[column.key] = getNestedValue(row, column.key)
              }
            })
            return processedRow
          }),
          {
            header: true,
            columns: props.columns,
          }
        )
        const blob = new Blob([csv], { type: 'text/x-csv' })
        const fileName =
          props.fileName + moment().format('_YYYY-MM-DD') + '.csv'
        saveAs(blob, fileName)
      }}
    >
      Export
    </Button>
  )
}
