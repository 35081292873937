import { ReactElement } from 'react'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'

type Props = {
  children:
    | ReactElement<typeof SnapshotLine>
    | Array<ReactElement<typeof SnapshotLine>>
}

export default function SnapshotSection({ children }: Readonly<Props>) {
  return <div className="tw-flex tw-flex-col tw-gap-1 tw-pt-4">{children}</div>
}
