import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import MetadataVerificationIcon from '@/pages/serviceActivity/residential/snapShots/icons/MetadataVerificationIcon.svg'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotCard from '@/pages/serviceActivity/residential/snapShots/components/SnapshotCard.tsx'
import AgeSnapshot from '@/pages/serviceActivity/residential/snapShots/components/AgeSnapshot.tsx'

export default function MetadataVerificationSnapshot() {
  return (
    <SnapshotCard>
      <SnapshotSummary
        icon={<img alt="icon" src={MetadataVerificationIcon} />}
        count={1977}
        description="Assets in Metadata Verification"
      />
      <SnapshotSections>
        <SnapshotSection>
          <SnapshotLine label="Total Open Tickets">
            <SnapshotValue value="326" displayType="primary" />
          </SnapshotLine>
          <SnapshotLine
            label="Percent of assets in Metadata Verification with 1+ tickets "
            description="This is a description This is a description This is a description"
          >
            <SnapshotValue value="16.5%" />
          </SnapshotLine>
        </SnapshotSection>
        <SnapshotSection>
          <SnapshotLine label="Pending client action">
            <SnapshotValue value="345" displayType="danger" />
          </SnapshotLine>
        </SnapshotSection>
        <AgeSnapshot
          oneMonth={{ value: 90, percentage: 'XX%' }}
          twoMonths={{ value: 38, percentage: 'XX%' }}
          threeMonths={{ value: 29, percentage: 'XX%' }}
          threeMonthsPlus={{ value: 33, percentage: 'XX%' }}
        />
      </SnapshotSections>
    </SnapshotCard>
  )
}
