import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import ActiveIcon from '@/pages/serviceActivity/residential/snapShots/icons/ActiveIcon.svg'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotCard from '@/pages/serviceActivity/residential/snapShots/components/SnapshotCard.tsx'
import AgeSnapshot from '@/pages/serviceActivity/residential/snapShots/components/AgeSnapshot.tsx'

export default function ActiveAssetsSnapshot() {
  return (
    <SnapshotCard>
      <SnapshotSummary
        icon={<img alt="icon" src={ActiveIcon} />}
        count={2013}
        description="Assets in Performance Verification"
      />
      <SnapshotSections>
        <SnapshotSection>
          <SnapshotLine label="Tickets for Active assets">
            <SnapshotValue value="2,145" displayType="primary" />
          </SnapshotLine>
          <SnapshotLine
            label="Percent of active assets with 1+ tickets"
            description="This is a description This is a description This is a description"
          >
            <SnapshotValue value="11.8%" />
          </SnapshotLine>
        </SnapshotSection>
        <SnapshotSection>
          <SnapshotLine label="Pending client action">
            <SnapshotValue value="214" displayType="danger" />
          </SnapshotLine>
        </SnapshotSection>
        <SnapshotSection>
          <SnapshotLine label="Non-Covered Service Requests">
            <SnapshotValue value="XXX" />
          </SnapshotLine>
        </SnapshotSection>
        <SnapshotSection>
          <SnapshotLine label="Remote Troubleshooting">
            <SnapshotValue value="XXX" />
          </SnapshotLine>
          <SnapshotLine label="Field Service">
            <SnapshotValue value="XXX" />
          </SnapshotLine>
        </SnapshotSection>
        <AgeSnapshot
          oneMonth={{ value: 22, percentage: 'XX%' }}
          twoMonths={{ value: 24, percentage: 'XX%' }}
          threeMonths={{ value: 26, percentage: 'XX%' }}
          threeMonthsPlus={{ value: 28, percentage: 'XX%' }}
        />
      </SnapshotSections>
    </SnapshotCard>
  )
}
