import type { WorkOrder } from '@/generated'
import WorkOrdersProvider from './providers/WorkOrdersProvider.tsx'
import WorkOrdersList from './WorkOrdersList.tsx'
import WorkOrderContent from './WorkOrderContent.tsx'

interface WorkOrdersPanelProps {
  workOrders: WorkOrder[]
}

export default function WorkOrdersPanel({
  workOrders,
}: Readonly<WorkOrdersPanelProps>) {
  return (
    <WorkOrdersProvider workOrders={workOrders}>
      <div className="tw-flex tw-border tw-border-gray-200">
        <div className="tw-w-72 tw-shrink-0 tw-border-r tw-border-gray-200 tw-pr-4">
          <WorkOrdersList />
        </div>

        <div className="tw-grow">
          <WorkOrderContent />
        </div>
      </div>
    </WorkOrdersProvider>
  )
}
