import React, { PropsWithChildren } from 'react'

export function PageTitle({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <div className="tw-mb-8 tw-inline-flex tw-h-9 tw-justify-start tw-gap-1.5">
      <div className="tw-text-2xl tw-font-bold tw-leading-9 tw-text-slate-700">
        {children}
      </div>
    </div>
  )
}
