import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { Link } from 'react-router-dom'
import { PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface AssetDetailLinkProps {
  assetId: number
  className?: string
}

export function AssetDetailLink({
  assetId,
  children,
  className,
}: PropsWithChildren<AssetDetailLinkProps>) {
  const { assetDetailEnabled } = useFeatureFlags()
  if (assetDetailEnabled) {
    return (
      <Link
        to={`/assets/${assetId}`}
        className={twMerge(
          'tw-font-bold tw-text-gray-900 hover:tw-text-blue-600 hover:tw-underline',
          className
        )}
      >
        {children}
      </Link>
    )
  } else {
    return children
  }
}
