import { Maybe } from '@/helpers/TypeHelpers.ts'
import { CellContext } from '@tanstack/react-table'
import React from 'react'

function getTicketStatusColor(status?: Maybe<string>): string | undefined {
  if (status?.toLowerCase() === 'pending client response/approval')
    return 'tw-text-red-600 tw-font-bold'
  return undefined
}

export function TicketStatusCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const status = ctx.getValue<Maybe<string>>()?.trim()
  return <span className={getTicketStatusColor(status)}>{status}</span>
}
