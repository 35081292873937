import { Portal } from '@headlessui/react'
import CaseViewModal from './CaseViewModal.tsx'
import { useAssetCaseQuery } from '@/api/AssetQueries.ts'

interface CaseViewModalContainerProps {
  show: boolean
  assetId: number
  caseId: string
  onClose: () => void
}

export default function CaseViewModalContainer({
  show,
  assetId,
  caseId,
  onClose,
}: Readonly<CaseViewModalContainerProps>) {
  const assetCaseQuery = useAssetCaseQuery(assetId, caseId)

  return (
    <Portal>
      {assetCaseQuery.isSuccess && (
        <CaseViewModal
          show={show}
          caseData={assetCaseQuery.data}
          onClose={onClose}
        />
      )}
    </Portal>
  )
}
