import { useWorkOrdersContext } from '../../providers/WorkOrdersProvider.tsx'
import VerticalPropertyItem from '../../../VerticalPropertyItem.tsx'
import { formatLocalDate } from '@/helpers/NativeDateTimeHelpers.ts'

export default function DetailsTabPanel() {
  const {
    selected: { workOrderNumber, quote, scheduleDate, summary },
  } = useWorkOrdersContext()

  return (
    <div>
      <div className="tw-border-b tw-border-gray-200 tw-px-4 tw-py-6">
        <h4 className="tw-mb-4 tw-font-bold tw-text-gray-900">
          Work Order Details
        </h4>

        <div className="tw-flex tw-justify-between tw-gap-6 tw-text-gray-500">
          <VerticalPropertyItem label="Work Order Number">
            {workOrderNumber}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Estimate">${quote}</VerticalPropertyItem>

          <VerticalPropertyItem label="Schedule Date">
            {scheduleDate ? formatLocalDate(scheduleDate) : ''}
          </VerticalPropertyItem>
        </div>
      </div>

      {summary && (
        <div className="tw-border-b tw-border-gray-200 tw-px-4 tw-py-6">
          <h4 className="tw-mb-4 tw-font-bold tw-text-gray-900">
            Summary Notes
          </h4>

          <p className="tw-text-gray-500">{summary}</p>
        </div>
      )}
    </div>
  )
}
