import { ServiceActivity } from '@/generated'
import { Row } from '@tanstack/react-table'

export function pendingClientActionSortFn(
  rowA: Row<ServiceActivity>,
  rowB: Row<ServiceActivity>
): 0 | 1 | -1 {
  const { pendingClientAction: pendingActionA } = rowA.original
  const { pendingClientAction: pendingActionB } = rowB.original
  if (pendingActionA === pendingActionB) {
    return 0
  }
  return pendingActionA ? 1 : -1
}
