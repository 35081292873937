import { CellContext } from '@tanstack/react-table'
import { ServiceActivity } from '@/generated'
import PendingClientActionIcon from '@/pages/serviceActivity/commercial/PendingClientActionIcon.svg'

export default function PendingClientActionCell(
  ctx: Readonly<CellContext<ServiceActivity, unknown>>
) {
  const { pendingClientAction } = ctx.row.original
  if (pendingClientAction) {
    return <img alt="Pending Action" src={PendingClientActionIcon} />
  }
  return null
}
