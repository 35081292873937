import moment from 'moment'
import React from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import {
  ISO_DATE_FORMAT,
  ISO_MONTH_FORMAT,
  parseIsoDate,
} from '@/helpers/MomentHelpers.ts'
import Calendar from './Calendar.tsx'
import {
  DateRangeSelectorActions,
  DateRangeSelectorState,
} from '@/components/core/datepickers/useDateRangeSelectorState.tsx'

export interface DateRangeSelectorProps {
  presets?: DateRangePreset[]
  state: DateRangeSelectorState
  actions: DateRangeSelectorActions
}

export type DateRangePreset = {
  name: string
  isoStartDate: string
  isoEndDate: string
}

export default function DateRangeSelector({
  state,
  actions,
  ...props
}: Readonly<DateRangeSelectorProps>): React.JSX.Element {
  const { setState } = actions
  function handlePrevMonthClicked() {
    setState((curState): DateRangeSelectorState => {
      return {
        ...curState,
        leftMonth: moment(curState.leftMonth).subtract(1, 'month'),
        rightMonth: moment(curState.rightMonth).subtract(1, 'month'),
      }
    })
  }

  function handleNextMonthClicked() {
    setState((curState): DateRangeSelectorState => {
      return {
        ...curState,
        leftMonth: moment(curState.leftMonth).add(1, 'month'),
        rightMonth: moment(curState.rightMonth).add(1, 'month'),
      }
    })
  }

  function handleDayHover(day: string) {
    const date = moment(day, ISO_DATE_FORMAT, true).startOf('day')
    if (
      state.phase === 'selectingEnd' &&
      !!state.startDate &&
      date.isValid() &&
      date.isSameOrAfter(state.startDate)
    ) {
      setState(curState => ({
        ...curState,
        endDate: date,
      }))
    }
  }

  function handleDayClicked(day: string) {
    setState(curState => {
      const date = moment(day, ISO_DATE_FORMAT, true).startOf('day')
      const newState = {
        ...curState,
      }

      if (
        curState.phase !== 'selectingEnd' ||
        curState.startDate?.isAfter(date)
      ) {
        newState.startDate = date
        newState.endDate = null
        newState.phase = 'selectingEnd'
      } else if (curState.startDate?.isSameOrBefore(date)) {
        newState.endDate = date
        newState.phase = 'completed'
      }

      return newState
    })
  }

  function applyPreset(preset: DateRangePreset) {
    const startDate = parseIsoDate(preset.isoStartDate)?.startOf('day') ?? null
    const endDate = parseIsoDate(preset.isoEndDate)?.startOf('day') ?? null
    actions.setSelectedDateRange(startDate, endDate)
  }

  function isPresetSelected(preset: DateRangePreset) {
    const presetStart = parseIsoDate(preset.isoStartDate, false)
    const presetEnd = parseIsoDate(preset.isoEndDate, false)
    return (
      state.phase === 'completed' &&
      presetStart &&
      presetEnd &&
      presetStart.isSame(state.startDate) &&
      presetEnd.isSame(state.endDate)
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={twMerge(
        `tw-flex tw-max-w-fit tw-flex-row tw-gap-x-8 tw-gap-y-2 tw-bg-white`,
        props.presets ? 'tw-grid-cols-3' : 'tw-grid-cols-2'
      )}
      onMouseDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      {props.presets && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-p-2">
          <div className="tw-select-none tw-border-0 tw-bg-white tw-text-sm tw-font-semibold tw-text-gray-900">
            Time Presets
          </div>
          <div className="tw-flex tw-grow tw-flex-col tw-gap-2 tw-border-y-0 tw-border-l-0 tw-border-solid tw-border-r-gray-200 tw-pr-2">
            {props.presets.map(preset => (
              <button
                key={preset.name}
                className={twMerge(
                  `tw-cursor-pointer tw-rounded-md tw-pb-1 tw-pl-2 tw-pr-2 tw-pt-1 tw-text-left tw-text-sm tw-text-gray-500 hover:tw-bg-blue-50 hover:tw-text-blue-600`,
                  isPresetSelected(preset)
                    ? 'tw-bg-blue-50 tw-text-blue-600'
                    : undefined
                )}
                onClick={() => {
                  applyPreset(preset)
                }}
              >
                {preset.name}
              </button>
            ))}
          </div>
        </div>
      )}
      <Calendar
        isoCurrentMonth={state.leftMonth.format(ISO_MONTH_FORMAT)}
        isoMinDate={state.minDate?.format(ISO_DATE_FORMAT)}
        isoMaxDate={state.maxDate?.format(ISO_DATE_FORMAT)}
        selection={{
          kind: 'range',
          isComplete: state.phase !== 'selectingEnd',
          value: {
            isoStartDate: state.startDate?.format(ISO_DATE_FORMAT) ?? null,
            isoEndDate: state.endDate?.format(ISO_DATE_FORMAT) ?? null,
          },
        }}
        onPrevMonthClicked={handlePrevMonthClicked}
        onDayHover={handleDayHover}
        onDayClicked={handleDayClicked}
      />

      <Calendar
        isoCurrentMonth={state.rightMonth.format(ISO_MONTH_FORMAT)}
        isoMinDate={state.minDate?.format(ISO_DATE_FORMAT)}
        isoMaxDate={state.maxDate?.format(ISO_DATE_FORMAT)}
        selection={{
          kind: 'range',
          isComplete: state.phase !== 'selectingEnd',
          value: {
            isoStartDate: state.startDate?.format(ISO_DATE_FORMAT) ?? null,
            isoEndDate: state.endDate?.format(ISO_DATE_FORMAT) ?? null,
          },
        }}
        onNextMonthClicked={handleNextMonthClicked}
        onDayHover={handleDayHover}
        onDayClicked={handleDayClicked}
      />
    </div>
  )
}
