import { Modal } from '@/components/core/modals/Modal.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import NullTicketSummaryImage from '@/assets/null-ticket-summary.svg?react'
import type { ServiceActivity } from '@/generated'

interface TicketSummaryModalProps {
  show: boolean
  serviceActivity: ServiceActivity
  onClose: () => void
}

export default function TicketSummaryModal({
  show,
  serviceActivity,
  onClose,
}: Readonly<TicketSummaryModalProps>) {
  return (
    <div className="tw-z-modal">
      <Modal show={show} onClose={onClose}>
        <Modal.Header onClose={onClose}>
          {serviceActivity.subject} Case Summary
        </Modal.Header>
        <Modal.Body>
          {serviceActivity.summary ? (
            <div className="tw-whitespace-pre-line">
              {serviceActivity.summary}
            </div>
          ) : (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
              <NullTicketSummaryImage />

              <p className="tw-text-sm tw-font-medium tw-text-gray-500">
                No summary notes available for this ticket.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-flex-row tw-justify-end">
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
