import { PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { AlignType, TableStyles } from './TableStyles.ts'
import { twJoin } from 'tailwind-merge'

type SimpleTableProps = {
  className?: string
  fixedLayout?: boolean
}

export function SimpleTable({
  className,
  children,
  fixedLayout,
}: PropsWithChildren<SimpleTableProps>) {
  return (
    <div
      className={twMerge(className, `tw-text-left tw-text-sm tw-text-gray-500`)}
    >
      <table
        className={twJoin('tw-w-full', fixedLayout ? 'tw-table-fixed' : '')}
      >
        {children}
      </table>
    </div>
  )
}

function SimpleTableHeader({ children }: Readonly<PropsWithChildren>) {
  return (
    <thead className="tw-bg-gray-50 tw-text-xs tw-uppercase tw-text-gray-700">
      <tr>{children}</tr>
    </thead>
  )
}

function SimpleTableBody({ children }: Readonly<PropsWithChildren>) {
  return <tbody className="tw-overflow-y-scroll tw-bg-white">{children}</tbody>
}

type SimpleTableRowProps = {
  className?: string
  isSelected: boolean
  isFirstRow: boolean
  onClick?: (event: unknown) => void
}

function SimpleTableRow({
  className,
  children,
  isSelected,
  isFirstRow,
  onClick,
}: PropsWithChildren<SimpleTableRowProps>) {
  const getHoverClass = () => {
    if (onClick && isSelected) return 'hover:tw-bg-blue-200'
    if (onClick) return 'hover:tw-bg-gray-200'
  }
  return (
    <tr
      className={twJoin(
        'tw-text-sm tw-font-normal',
        isSelected ? 'tw-bg-blue-50' : undefined,
        getHoverClass(),
        !isFirstRow && 'tw-border-t tw-border-gray-300',
        className
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  )
}

interface SimpleTableCellProps {
  onClick?: (event: unknown) => void
  noWrap?: boolean
  align?: AlignType
  className?: string
}

function SimpleTableCell({
  children,
  noWrap,
  align,
  onClick,
  className,
}: PropsWithChildren<SimpleTableCellProps>) {
  return (
    <td
      className={twJoin(
        'tw-px-4 tw-py-4',
        noWrap ? 'tw-whitespace-nowrap' : null,
        TableStyles.align[align ?? 'left'],
        className
      )}
      onClick={onClick}
    >
      {children}
    </td>
  )
}

SimpleTableHeader.displayName = 'SimpleTable.Header'
SimpleTableBody.displayName = 'SimpleTable.Body'
SimpleTableRow.displayName = 'SimpleTable.Row'
SimpleTableCell.displayName = 'SimpleTable.Cell'

SimpleTable.Header = SimpleTableHeader
SimpleTable.Body = SimpleTableBody
SimpleTable.Row = SimpleTableRow
SimpleTable.Cell = SimpleTableCell
