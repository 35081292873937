import SolarAssetsImage from '../assets/solar-assets.svg?react'

export function LoadingPage() {
  return (
    <div
      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
      data-testid="loading-page"
    >
      <SolarAssetsImage />
      <div>We’re loading your data. This shouldn’t take long.</div>
    </div>
  )
}
