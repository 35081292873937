import { GraphSeriesType } from '@/components/core/graphs/useGraphControls.ts'

export type GraphSeriesTypeOption = {
  value: GraphSeriesType
  label: string
}

export const GraphSeriesOptions: GraphSeriesTypeOption[] = [
  {
    value: 'line',
    label: 'Line',
  },
  {
    value: 'column',
    label: 'Column',
  },
]
