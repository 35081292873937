import { useMemo } from 'react'
import {
  FacetFilterData,
  facetFilterData,
  FacetFilterValue,
} from '@/components/core/table/filters/facetFilterFn.ts'
import { FilterDrawerSection } from './FilterDrawerSection.tsx'
import { FilterDrawerFacetFilterDef } from './FiltersDrawerButton.tsx'

interface FilterDrawerFacetSectionProps<T> {
  readonly filterDef: FilterDrawerFacetFilterDef<T>
  readonly rowData: T[] | undefined
  readonly filterValue: FacetFilterValue
  readonly onStateChange: (state: FacetFilterData | null) => void
}

export function FilterDrawerFacetSection<T>({
  filterDef,
  rowData,
  filterValue,
  onStateChange,
}: FilterDrawerFacetSectionProps<T>) {
  const facets = useMemo<Set<string>>(() => {
    const columnValues = rowData?.map(r => filterDef.rowDataAccessor(r))
    const facets = new Set<string | null>(columnValues)
    facets.delete(null)
    return facets as Set<string>
  }, [filterDef, rowData])
  const sortedFacets = useMemo<string[]>(
    () =>
      Array.from(facets.keys()).sort(
        filterDef.sortingFn ??
          ((a: string, b: string) => a.toString().localeCompare(b.toString()))
      ),
    [facets, filterDef.sortingFn]
  )

  const selected = new Set(filterValue?.selectedFacets)

  const onToggle = (value: string) => {
    if (selected.has(value)) selected.delete(value)
    else selected.add(value)

    onStateChange(facetFilterData(selected))
  }

  const onClear = () => {
    onStateChange(null)
  }

  if (facets.size === 0) {
    return <></>
  }

  return (
    <FilterDrawerSection header={filterDef.header} onClear={onClear}>
      {sortedFacets.map(value => {
        const key = `${filterDef.header}:${value}`
        return (
          <div
            key={key}
            className="tw-m-0 tw-flex tw-cursor-pointer tw-items-center tw-py-2 tw-text-sm tw-font-normal tw-text-gray-700"
          >
            <input
              id={key}
              type="checkbox"
              className={`tw-m-0 tw-mr-2 tw-cursor-pointer tw-rounded tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-p-0 
                  tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500`}
              aria-label={value}
              onChange={() => {
                onToggle(value)
              }}
              checked={selected.has(value)}
            />
            <label
              className="tw-grow tw-cursor-pointer tw-select-none"
              htmlFor={key}
              aria-label={value}
            >
              {value}
            </label>
          </div>
        )
      })}
    </FilterDrawerSection>
  )
}
