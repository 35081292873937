import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'
import {
  Configuration,
  FeatureFlags,
  SPAConfig,
  SpaControllerApi,
} from '@/generated'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'

const spaQueryKeys = {
  config: 'config',
  featureFlags: 'featureFlags',
  clientProfileIds: 'clientProfileIds',
}

function useSpaClient() {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new SpaControllerApi(new Configuration(config))
  }, [config])
}

export function useSPAConfigQuery(): UseQueryResult<SPAConfig> {
  // Avoid using the useSpaClient() hook since useAuth() hook will error prior to auth being initialized,
  // which depends on the result of this query.
  const client = new SpaControllerApi(
    new Configuration({
      basePath: window.location.origin,
    })
  )
  return useQuery({
    queryKey: [spaQueryKeys.config],

    queryFn: async ({ signal }) => {
      const response = await client.getConfig({ signal })
      return response.data
    },
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export function useFeatureFlagQuery(): UseQueryResult<FeatureFlags> {
  const client = useSpaClient()
  const auth = useAuth()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [spaQueryKeys.featureFlags, clientProfileId],

    queryFn: async ({ signal }) => {
      const response = await client.getFlags(clientProfileId, { signal })
      return response.data
    },

    enabled: auth.isAuthenticated && !!clientProfileId,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export function useClientProfileIdsQuery(): UseQueryResult<string[]> {
  const client = useSpaClient()
  const auth = useAuth()
  return useQuery({
    queryKey: [spaQueryKeys.clientProfileIds],

    queryFn: async ({ signal }) => {
      const response = await client.getClientProfileIds({ signal })
      return response.data.ids
    },

    enabled: auth.isAuthenticated,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}
