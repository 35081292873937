import { ReactElement } from 'react'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'

type Props = {
  children:
    | ReactElement<typeof SnapshotSection>
    | Array<ReactElement<typeof SnapshotSection>>
}

export default function SnapshotSections({ children }: Readonly<Props>) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-divide-y">{children}</div>
  )
}
