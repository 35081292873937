import React, { ChangeEvent, useMemo, useState } from 'react'
import moment from 'moment'
import { Portal } from '@headlessui/react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import {
  DATE_INPUT_FORMAT,
  ISO_DATE_FORMAT,
  ISO_MONTH_FORMAT,
  parseIsoDate,
  parseIsoMonth,
} from '@/helpers/MomentHelpers.ts'
import Calendar from '@/components/core/datepickers/Calendar.tsx'
import {
  autoPlacement,
  offset,
  Placement,
  useFloating,
} from '@floating-ui/react'

interface DateInputProps {
  readonly className?: string
  readonly name?: string
  readonly tabIndex?: number
  readonly placeholder?: string
  readonly format?: string
  readonly value?: string
  readonly label?: string
  readonly isoDefaultMonth?: string
  readonly isoDefaultDate?: string
  readonly isoMinDate?: string
  readonly isoMaxDate?: string
  readonly allowedFloatingPlacements?: Placement[]
  readonly onFocus?: React.FocusEventHandler
  readonly onBlur?: React.FocusEventHandler
  readonly onChange?: React.ChangeEventHandler<HTMLInputElement>
}

type DateInputState = {
  focused: boolean
  isoCurrentMonth: string
  isoSelectedDate: string | null
  rawValue: string
}

export default function DateInput({
  format = DATE_INPUT_FORMAT,
  ...props
}: DateInputProps): React.JSX.Element {
  const defaultDate = parseIsoDate(props.isoDefaultDate ?? null)
  const parsedValue = props.value
    ? moment(props.value, [moment.ISO_8601, format], true)
    : null

  const defaultMonth = parseIsoMonth(props.isoDefaultMonth ?? null)
  const isoCurrentMonth = useMemo(() => {
    const month =
      (parsedValue ? moment(parsedValue).startOf('month') : undefined) ??
      (defaultDate ? moment(defaultDate).startOf('month') : undefined) ??
      defaultMonth ??
      moment().startOf('day').startOf('month')
    return month.format(ISO_MONTH_FORMAT)
  }, [parsedValue, defaultMonth, defaultDate])

  const [state, setState] = useState<DateInputState>({
    focused: false,
    isoCurrentMonth,
    isoSelectedDate:
      parsedValue?.format(ISO_DATE_FORMAT) ?? props.isoDefaultDate ?? null,
    rawValue: parsedValue?.isValid()
      ? parsedValue.format(format)
      : defaultDate?.format(format) ?? '',
  })

  const float = useFloating<HTMLDivElement>({
    middleware: [
      offset(8),
      autoPlacement({
        alignment: 'start',
        allowedPlacements: props.allowedFloatingPlacements,
      }),
    ],
  })

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>()

  function focusHandler(e: React.FocusEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, focused: true }))
    if (props.onFocus) props.onFocus(e)
  }

  function blurHandler(e: React.FocusEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, focused: false }))
    if (props.onBlur) props.onBlur(e)
  }

  function prevMonthClickHandler() {
    setState(curState => ({
      ...curState,
      isoCurrentMonth:
        parseIsoMonth(curState.isoCurrentMonth)
          ?.subtract(1, 'month')
          .format(ISO_MONTH_FORMAT) ?? '',
    }))
  }

  function nextMonthClickHandler() {
    setState(curState => ({
      ...curState,
      isoCurrentMonth:
        parseIsoMonth(curState.isoCurrentMonth)
          ?.add(1, 'month')
          .format(ISO_MONTH_FORMAT) ?? '',
    }))
  }

  function dayClickHandler(isoDate: string) {
    const parsedIsoDate = parseIsoDate(isoDate)
    const formattedDate = parsedIsoDate?.format(format) ?? null

    setState(prev => ({
      ...prev,
      isoCurrentMonth: parsedIsoDate
        ? moment(parsedIsoDate).startOf('month').format(ISO_MONTH_FORMAT)
        : prev.isoCurrentMonth,
      isoSelectedDate: parsedIsoDate?.format(ISO_DATE_FORMAT) ?? null,
      rawValue: formattedDate ?? '',
    }))

    // trigger a changed event for the input text box
    if (inputRef) {
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set?.call(inputRef, formattedDate ?? '')
      const changeEvent = new Event('change', { bubbles: true })
      inputRef.dispatchEvent(changeEvent)
    }
  }

  function changedHandler(e: ChangeEvent<HTMLInputElement>) {
    const date = moment(e.target.value, format, true)

    setState(curState => ({
      ...curState,
      isoCurrentMonth: date.isValid()
        ? moment(date).startOf('month').format(ISO_MONTH_FORMAT)
        : curState.isoCurrentMonth,
      isoSelectedDate: date.isValid() ? date.format(ISO_DATE_FORMAT) : null,
      rawValue: e.target.value,
    }))
    if (props.onChange) props.onChange(e)
  }

  return (
    <>
      <div
        ref={float.refs.setReference}
        className={twMerge(
          props.className,
          `tw-relative tw-inline-block tw-max-w-sm`
        )}
      >
        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
          <svg
            aria-hidden="true"
            className="tw-size-5 tw-text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            />
          </svg>
        </div>
        <input
          type="text"
          ref={setInputRef}
          name={props.name}
          tabIndex={props.tabIndex}
          aria-label={props.label}
          autoComplete="off"
          className="tw-block tw-w-full tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-50 tw-p-2.5 tw-pl-10 tw-text-sm tw-text-gray-900 focus:tw-border-blue-500 focus:tw-ring-blue-500"
          placeholder={props.placeholder ?? 'Select date'}
          value={state.rawValue}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onChange={changedHandler}
        />
      </div>

      {state.focused && (
        <Portal>
          <div
            ref={float.refs.setFloating}
            style={float.floatingStyles}
            className="tw-z-popover"
          >
            <div className="tw-shrink-0 tw-rounded-lg tw-border-b tw-border-gray-400 tw-bg-white tw-p-2 tw-drop-shadow-md">
              <Calendar
                showDaysNotInMonth
                isoCurrentMonth={state.isoCurrentMonth}
                selection={{
                  kind: 'single',
                  isoDate: state.isoSelectedDate,
                }}
                isoMinDate={props.isoMinDate}
                isoMaxDate={props.isoMaxDate}
                onDayClicked={dayClickHandler}
                onPrevMonthClicked={prevMonthClickHandler}
                onNextMonthClicked={nextMonthClickHandler}
              />
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}
