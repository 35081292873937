import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  type PropsWithChildren,
} from 'react'
import type { WorkOrder } from '@/generated'

interface IWorkOrdersContext {
  workOrders: WorkOrder[]
  selected: WorkOrder
  onSelect: (woId: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const WorkOrdersContext = createContext<IWorkOrdersContext>(null!)

interface WorkOrdersProviderProps {
  workOrders: WorkOrder[]
}

export default function WorkOrdersProvider({
  workOrders,
  children,
}: PropsWithChildren<WorkOrdersProviderProps>) {
  const [selected, setSelected] = useState<WorkOrder>(workOrders[0])

  const handleSelect = useCallback<IWorkOrdersContext['onSelect']>(
    woId => {
      const selected = workOrders.find(({ id }) => id === woId)

      selected && setSelected(selected)
    },
    [workOrders]
  )

  const value = useMemo(
    () => ({
      workOrders,
      selected,
      onSelect: handleSelect,
    }),
    [workOrders, selected, handleSelect]
  )
  return (
    <WorkOrdersContext.Provider value={value}>
      {children}
    </WorkOrdersContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useWorkOrdersContext = () => {
  return useContext(WorkOrdersContext)
}
