import React from 'react'
import { useUser } from '@/components/auth/AuthHooks.ts'
import { ApprovalTasksTable } from './tasks/ApprovalTasksTable.tsx'
import { NavTab, NavTabs } from '@/components/core/nav/NavTabs.tsx'
import { PageTitle } from '@/components/text/PageTitle.tsx'
import { PageCard } from '@/components/cards/PageCard.tsx'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { useGetPendingClientResponseTickets } from '@/api/ApiQueries.ts'

export function DispatchApprovalPage(): React.JSX.Element {
  const user = useUser()
  const awaitingApprovalTicketsQuery = useGetPendingClientResponseTickets()
  const rows = awaitingApprovalTicketsQuery.data
  const showAssetManagerColumn = useHasMultipleAssetManagers()

  return (
    <div className="tw-p-8">
      <PageTitle>Welcome back, {user.name}!</PageTitle>
      <PageCard>
        <PageCard.Header>
          <PageCard.Title>My Tasks</PageCard.Title>
          <NavTabs>
            <NavTab
              title={`Awaiting Approval (${rows?.length ?? ''})`}
              path="/home/awaiting-approval"
            />
          </NavTabs>
        </PageCard.Header>
        <div className="tw-mx-4 tw-overflow-auto">
          <ApprovalTasksTable
            tasks={rows}
            error={awaitingApprovalTicketsQuery.isError}
            loading={awaitingApprovalTicketsQuery.isLoading}
            showAssetManagerColumn={showAssetManagerColumn}
          />
        </div>
      </PageCard>
    </div>
  )
}
