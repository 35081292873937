import React, { useState } from 'react'
import { Modal } from '@/components/core/modals/Modal.tsx'
import iconCheck from '@iconify/icons-material-symbols/done'
import iconPause from '@iconify/icons-material-symbols/pause-circle-outline'
import iconNo from '@iconify/icons-material-symbols/block'
import Button from '@/components/core/buttons/Button.tsx'
import RichRadioButton from '@/components/core/buttons/RichRadioButton.tsx'
import { DispatchAction } from '@/generated'
import { Maybe } from '@/helpers/TypeHelpers.ts'

interface DispatchApprovalModalProps {
  readonly show: boolean
  readonly onClose: () => void
  readonly onSubmit: (option: DispatchAction) => void
}

export function DispatchApprovalModal({
  show,
  onClose,
  onSubmit,
}: Readonly<DispatchApprovalModalProps>): React.JSX.Element {
  const [option, setOption] = useState<Maybe<DispatchAction>>(null)

  const handleSelectionChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const newSelection = e.target.value as DispatchAction
    setOption(newSelection)
  }

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header onClose={onClose}>Select action</Modal.Header>
      <Modal.Body className="tw-flex tw-flex-col">
        <p className="tw-text-sm tw-text-slate-500">
          How would you like to action this dispatch request?
        </p>
        <ul>
          <RichRadioButton
            icon={iconCheck}
            iconColor="green"
            onChange={handleSelectionChange}
            option={DispatchAction.Approve}
            currentOption={option}
          >
            Approve dispatch
          </RichRadioButton>
          <RichRadioButton
            icon={iconPause}
            iconColor="grey"
            onChange={handleSelectionChange}
            option={DispatchAction.HoldCm}
            currentOption={option}
          >
            Hold until next corrective dispatch
          </RichRadioButton>
          <RichRadioButton
            icon={iconPause}
            iconColor="grey"
            onChange={handleSelectionChange}
            option={DispatchAction.HoldPm}
            currentOption={option}
          >
            Hold until next preventative maintenance dispatch
          </RichRadioButton>
          <RichRadioButton
            icon={iconNo}
            iconColor="red"
            onChange={handleSelectionChange}
            option={DispatchAction.Decline}
            currentOption={option}
          >
            Decline dispatch
          </RichRadioButton>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!option}
          onClick={() => {
            if (option) {
              onSubmit(option)
            }
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
