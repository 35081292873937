import moment, { Moment } from 'moment/moment'
import React, { useCallback, useState } from 'react'
import { ISO_DATE_FORMAT, ISO_MONTH_FORMAT } from '@/helpers/MomentHelpers.ts'

type CurrentMonthPosition = 'left' | 'right'

export type DateRangeSelectorState = {
  currentMonthPosition?: CurrentMonthPosition
  isDirty: boolean
  phase: 'selectingStart' | 'selectingEnd' | 'completed'
  leftMonth: moment.Moment
  rightMonth: moment.Moment
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  minDate: moment.Moment | null
  maxDate: moment.Moment | null
}

export type DateRangeSelectorActions = {
  setSelectedDateRange: (
    startDate: Moment | null,
    endDate: Moment | null
  ) => void
  setState: React.Dispatch<React.SetStateAction<DateRangeSelectorState>>
}

export function useDateRangeSelectorState({
  currentMonthPosition = 'left',
  ...props
}: {
  currentMonthPosition?: CurrentMonthPosition
  isoCurrentMonth?: string
  isoStartDate?: string
  isoEndDate?: string
  isoMinDate?: string
  isoMaxDate?: string
}): [DateRangeSelectorState, DateRangeSelectorActions] {
  const today = moment().startOf('day')

  const curMonth = props.isoCurrentMonth
    ? moment(props.isoCurrentMonth, ISO_MONTH_FORMAT, true)
    : moment(today).startOf('month')

  const startDate = props.isoStartDate
    ? moment(props.isoStartDate, ISO_DATE_FORMAT, true).startOf('day')
    : null

  const endDate = props.isoEndDate
    ? moment(props.isoEndDate, ISO_DATE_FORMAT, true).startOf('day')
    : null
  const minDate = props.isoMinDate
    ? moment(props.isoMinDate, ISO_DATE_FORMAT, true)
    : null
  const maxDate = props.isoMaxDate
    ? moment(props.isoMaxDate, ISO_DATE_FORMAT, true)
    : null

  const [leftMonth, rightMonth] = calculateCalendarMonths(
    currentMonthPosition,
    curMonth,
    startDate,
    endDate
  )

  const initialState: DateRangeSelectorState = {
    isDirty: false,
    phase: 'completed',
    minDate,
    maxDate,
    currentMonthPosition,
    leftMonth,
    rightMonth,
    startDate,
    endDate,
  }

  const [state, setState] = useState<DateRangeSelectorState>(initialState)

  const updateSelectedDateRange = useCallback(
    (startDate: Moment, endDate: Moment) => {
      const isValidStartDate = !minDate || startDate.isSameOrAfter(minDate)
      const isValidEndDate = !maxDate || endDate.isSameOrBefore(maxDate)
      if (isValidStartDate && isValidEndDate) {
        setState(curState => {
          const [leftMonth, rightMonth] = calculateCalendarMonths(
            currentMonthPosition,
            curMonth,
            startDate,
            endDate
          )
          return {
            ...curState,
            phase: 'completed',
            leftMonth,
            rightMonth,
            startDate,
            endDate,
          }
        })
      } else {
        console.error(
          `Could not apply date range:`,
          startDate,
          endDate,
          minDate,
          maxDate
        )
      }
    },
    [setState, minDate, maxDate, curMonth, currentMonthPosition]
  )

  const clearSelectedDateRange = useCallback(() => {
    setState(curState => ({
      ...curState,
      startDate: null,
      endDate: null,
      phase: 'selectingStart',
    }))
  }, [setState])

  const actions: DateRangeSelectorActions = {
    setSelectedDateRange: useCallback(
      (startDate, endDate) => {
        if (startDate && endDate) {
          updateSelectedDateRange(startDate, endDate)
        } else {
          clearSelectedDateRange()
        }
      },
      [updateSelectedDateRange, clearSelectedDateRange]
    ),
    setState,
  }
  return [state, actions]
}

function calculateCalendarMonths(
  currentMonthPosition: CurrentMonthPosition,
  curMonth: Moment,
  startDate: Moment | null,
  endDate: Moment | null
): [Moment, Moment] {
  let leftMonth: Moment
  let rightMonth: Moment
  if (currentMonthPosition === 'left') {
    leftMonth =
      startDate !== null ? moment(startDate).startOf('month') : moment(curMonth)
    rightMonth =
      startDate !== null
        ? moment(startDate).startOf('month').add(1, 'month')
        : moment(curMonth).add(1, 'month')
  } else {
    leftMonth =
      endDate !== null
        ? moment(endDate).startOf('month').subtract(1, 'month')
        : moment(curMonth).subtract(1, 'month')
    rightMonth =
      endDate !== null ? moment(endDate).startOf('month') : moment(curMonth)
  }
  return [leftMonth, rightMonth]
}
