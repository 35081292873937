import Button from '@/components/core/buttons/Button.tsx'

export interface ClientProfileSelectorProps {
  readonly clientProfileIds: string[]
  readonly onSetClientProfileId: (clientProfileId: string) => void
}

export function ClientProfileSelector({
  clientProfileIds,
  onSetClientProfileId,
}: ClientProfileSelectorProps): React.JSX.Element {
  return (
    <div className="tw-min-w-[30vw] tw-flex-col">
      <p className="tw-mb-4 tw-text-sm tw-text-slate-500">
        Select the client account you want to log into
      </p>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {clientProfileIds.map(clientProfileId => (
          <Button
            key={clientProfileId}
            color="lightOutline"
            align="left"
            size="lg"
            block
            onClick={() => {
              onSetClientProfileId(clientProfileId)
            }}
          >
            {clientProfileId}
          </Button>
        ))}
      </div>
    </div>
  )
}
