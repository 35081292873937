import React, { useContext } from 'react'
import {
  GraphControlsContext,
  GraphSeriesConfig,
} from '@/components/core/graphs/useGraphControls.ts'
import { ColumnSeries, LineSeries } from 'react-jsx-highstock'
import {
  PointMarkerOptionsObject,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesStatesOptionsObject,
} from 'highcharts'
import { SeriesDataPoint } from '@/components/core/graphs/SeriesDataPoint.ts'

interface GraphSeriesProps {
  seriesConfig: GraphSeriesConfig
  data: SeriesDataPoint[]
  showInNavigator?: boolean
}

export function GraphSeries({
  data,
  seriesConfig,
  showInNavigator,
}: Readonly<GraphSeriesProps>): React.JSX.Element {
  const controlsState = useContext(GraphControlsContext).state
  const seriesId = seriesConfig.id
  const seriesType = controlsState.getSeriesType(seriesId)
  const commonOptions: Omit<SeriesLineOptions, 'type'> &
    Omit<SeriesColumnOptions, 'type'> = {
    name: seriesConfig.name,
    id: seriesId,
    data: data.map(dp => {
      return {
        x: dp.type === 'time-series' ? dp.date?.valueOf() : undefined,
        y: dp.value,
      }
    }),
    color: seriesConfig.color,
    visible: controlsState.isSeriesEnabled(seriesId),
    showInNavigator: !!showInNavigator,
  }
  const markerOptions: PointMarkerOptionsObject = {
    enabled: true,
    radius: 1,
    states: {
      hover: {
        enabled: true,
      },
    },
  }
  const stateOptions: SeriesStatesOptionsObject = {
    hover: {
      lineWidthPlus: 1,
    },
  }

  if (seriesType === 'line') {
    return (
      <LineSeries
        {...commonOptions}
        zIndex={1}
        lineWidth={2}
        marker={markerOptions}
        states={stateOptions}
        dashStyle={seriesConfig.lineDashStyle}
      />
    )
  } else {
    return <ColumnSeries {...commonOptions} zIndex={0} />
  }
}
