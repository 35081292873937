import {
  CellContext,
  ColumnDef,
  Row,
  RowData,
  sortingFns,
} from '@tanstack/react-table'
import React from 'react'
import { AlignType } from '../TableStyles.ts'
import { getTextFilterConfig, TextFilterMode } from './textFilterConfig.ts'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'

type Options<T extends RowData> = {
  key: NestedKeyOf<T>
  header?: string
  align?: AlignType
  mode?: TextFilterMode
  disableColumnFilter?: boolean
  sortingFn?: (r1: Row<T>, r2: Row<T>) => number
  cell?: (ctx: CellContext<T, unknown>) => React.JSX.Element
}

export default function textColumn<TData extends RowData, TValue>(
  options: Options<TData>
): ColumnDef<TData, TValue> {
  const sortEnabled = options.mode === 'sortable'
  const filterConfig = getTextFilterConfig<TData, TValue>(options)
  const columnDef: ColumnDef<TData, TValue> = {
    id: options.key,
    accessorKey: options.key,
    header: options.header,
    enableSorting: sortEnabled,
    sortingFn: options.sortingFn ?? sortingFns.alphanumeric,
    ...filterConfig,
    // if we put this above the ...filterConfig, then our meta properties will get clobbered by
    // the filterConfig's meta properties. Instead, try to merge the two together:
    meta: {
      align: options.align ?? 'left',
      // Don't clobber the filter config's meta
      ...filterConfig.meta,
    },
  }

  // Only add the cell property if it is defined
  // if we included it above as:
  // {
  //   cell: options.cell
  // }
  // the key would be defined but the value would not
  // and Tanstack Table will then not render anything
  // for the cell.
  if (options.cell) columnDef.cell = options.cell

  return columnDef
}
