import { DashStyleValue } from 'highcharts'
import { createContext, useCallback, useState } from 'react'
import { CheckboxState } from '@/components/core/form/Checkbox.tsx'

export type GraphSeriesConfig = {
  id: string
  name: string
  color: string
  lineDashStyle?: DashStyleValue
}

export type GraphSeriesType = 'line' | 'column'

export type GraphControlsGroup = {
  id: string
  seriesType: GraphSeriesType
  series: GraphControlsSeries[]
}
export type GraphControlsSeries = {
  id: string
  enabled: boolean
}

export type GraphControlsState = {
  getGroup: (groupId: string) => GraphControlsGroup
  getGroupSelectAllState: (groupId: string) => CheckboxState
  isSeriesEnabled: (seriesId: string) => boolean
  getSeriesType: (seriesId: string) => GraphSeriesType
}

export type GraphControlsActions = {
  setGroupSeriesType: (groupId: string, seriesType: GraphSeriesType) => void
  setGroupEnabled: (groupId: string, enabled: boolean) => void
  setSeriesEnabled: (seriesId: string, enabled: boolean) => void
}

export type GraphControlsModel = {
  state: GraphControlsState
  actions: GraphControlsActions
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const GraphControlsContext = createContext<GraphControlsModel>(null!)

export interface GraphControlsOptions {
  initialState: GraphControlsGroup[]
}

export function useGraphControls({
  initialState,
}: Readonly<GraphControlsOptions>): GraphControlsModel {
  const [state, setState] = useState<GraphControlsGroup[]>(initialState)

  const getGroup = useCallback(
    (groupId: string): GraphControlsGroup => {
      const groupState = state.find(g => g.id === groupId)
      if (groupState === undefined) {
        throw new Error(`Requested group ${groupId} does not exist.`)
      }
      return groupState
    },
    [state]
  )

  return {
    state: {
      getGroup,
      getGroupSelectAllState: useCallback(
        (groupId: string): CheckboxState => {
          const group = getGroup(groupId)
          return {
            checked: group.series.every(s => s.enabled),
            indeterminate: group.series.some(s => s.enabled),
          }
        },
        [getGroup]
      ),
      isSeriesEnabled: useCallback(
        (seriesId: string): boolean => {
          const series = state
            .flatMap(group => group.series)
            .find(series => series.id === seriesId)
          if (series === undefined) {
            throw new Error(`Requested series ${seriesId} does not exist`)
          }
          return series.enabled
        },
        [state]
      ),
      getSeriesType: useCallback(
        (seriesId: string): GraphSeriesType => {
          const group = state.find(
            g => g.series.find(s => s.id === seriesId) !== undefined
          )
          if (group === undefined) {
            throw new Error(`Requested series ${seriesId} does not exist`)
          }
          return group.seriesType
        },
        [state]
      ),
    },
    actions: {
      setGroupSeriesType: useCallback(
        (groupId: string, seriesType: GraphSeriesType) => {
          setState(current =>
            current.map(group => ({
              ...group,
              seriesType: group.id === groupId ? seriesType : group.seriesType,
            }))
          )
        },
        [setState]
      ),
      setGroupEnabled: useCallback(
        (groupId: string, enabled: boolean) => {
          setState(current =>
            current.map(group => ({
              ...group,
              series: group.series.map(series => ({
                ...series,
                enabled: group.id === groupId ? enabled : series.enabled,
              })),
            }))
          )
        },
        [setState]
      ),
      setSeriesEnabled: useCallback(
        (seriesId: string, enabled: boolean) => {
          setState(current =>
            current.map(group => ({
              ...group,
              series: group.series.map(series => ({
                ...series,
                enabled: series.id === seriesId ? enabled : series.enabled,
              })),
            }))
          )
        },
        [setState]
      ),
    },
  }
}
