import React, { createContext, useEffect, useMemo, useState } from 'react'

export type ClientProfileContextValue = {
  activeClientProfileId?: string
  setActiveClientProfileId: (clientProfileId?: string) => void
}

export const ClientProfileContext = createContext<ClientProfileContextValue>({
  setActiveClientProfileId: () => {},
})
export const clientProfileStorageKey = 'client-profile-id'

export function ClientProfileProvider(
  props: Readonly<React.PropsWithChildren>
): React.JSX.Element {
  const [activeClientProfileId, setActiveClientProfileId] = useState<
    string | undefined
  >(sessionStorage.getItem(clientProfileStorageKey) ?? undefined)

  useEffect(() => {
    if (activeClientProfileId) {
      sessionStorage.setItem(clientProfileStorageKey, activeClientProfileId)
    } else {
      sessionStorage.removeItem(clientProfileStorageKey)
    }
  }, [activeClientProfileId])

  const contextValue = useMemo(
    (): ClientProfileContextValue => ({
      activeClientProfileId: activeClientProfileId,
      setActiveClientProfileId: (id?: string) => {
        setActiveClientProfileId(id)
      },
    }),
    [activeClientProfileId]
  )

  return (
    <ClientProfileContext.Provider value={contextValue}>
      {props.children}
    </ClientProfileContext.Provider>
  )
}
