import { createContext } from 'react'
import { TableModel } from '@/components/core/table/useTable.tsx'
import { ServiceActivityTableData } from '@/pages/serviceActivity/residential/types.ts'
import {
  TableFilterState,
  TableFilterStateActions,
} from '@/components/core/table/filters/useTableFitlerState.ts'

export type ServiceActivityContextType = {
  tableModel: TableModel<ServiceActivityTableData>
  rows: Array<ServiceActivityTableData>
  tableFilterState: TableFilterState
  tableFilterStateActions: TableFilterStateActions
}

export const ServiceActivityTableContext = createContext<
  ServiceActivityContextType | undefined
>(undefined)
