import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { ServiceActivityTableData } from '@/pages/serviceActivity/residential/types.ts'
import { Priority } from '@/generated'

function randomGenerator(seed: number): () => number {
  let currentSeed = seed

  return function () {
    const a = 1664525
    const c = 1013904223
    const m = 2 ** 32

    currentSeed = (a * currentSeed + c) % m

    return currentSeed / m
  }
}

const random = randomGenerator(42)

function generateMockData(rows = 20000): ServiceActivityTableData[] {
  const data: ServiceActivityTableData[] = []
  const ticketTypes = ['Issue', 'Request', 'Complaint', 'System failure']
  const statuses = ['Open', 'Closed', 'Pending']
  const priorities: Priority[] = ['Urgent', 'High', 'Normal', 'Low']
  const names = ['John Doe', 'Jane Smith', 'Alice Johnson', 'Bob Brown']
  const installers = ['TechInstall Inc.', 'QuickFix Co.', 'SafeTech Solutions']

  for (let i = 0; i < rows; i++) {
    data.push({
      ticketId: (Math.floor(random() * 90000000) + 10000000).toString(),
      ticketType: ticketTypes[Math.floor(random() * ticketTypes.length)],
      customerName: names[Math.floor(random() * names.length)],
      assetId: (Math.floor(random() * 9000) + 1000).toString(),
      creationReason: `Customer reported a ${ticketTypes[Math.floor(random() * ticketTypes.length)].toLowerCase()}.`,
      originalInstaller: installers[Math.floor(random() * installers.length)],
      ticketStatus: statuses[Math.floor(random() * statuses.length)],
      createdDate: new Date(Date.now() - Math.floor(random() * 10000000000)),
      priority: priorities[Math.floor(random() * priorities.length)],
      otherOpenTicketsCount: Math.floor(random() * 10),
    })
  }
  return data
}

export default function useServiceActivityQueryResidential() {
  const auth = useAuth()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: ['useServiceActivityQueryResidential', clientProfileId],
    queryFn: (): Promise<ServiceActivityTableData[]> => {
      return Promise.resolve(generateMockData())
    },
    enabled: auth.isAuthenticated,
  })
}
