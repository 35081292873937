import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { formatLocalDate } from '@/helpers/NativeDateTimeHelpers.ts'

export function DateCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const dateTime = ctx.getValue<string>()
  if (dateTime) return <>{formatLocalDate(dateTime)}</>
  return <></>
}
