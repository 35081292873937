import React from 'react'
import { TableModel } from '@/components/core/table/useTable.tsx'

export interface ServiceActivityTableNoDataMessageProps<T> {
  readonly tableModel: TableModel<T>
}

export function ServiceActivityTableNoDataMessage<T>({
  tableModel,
}: ServiceActivityTableNoDataMessageProps<T>): React.JSX.Element {
  return (
    <div className="tw-flex tw-min-h-[300px] tw-flex-col tw-items-center tw-justify-center tw-gap-2">
      <div className="tw-text-lg tw-font-bold tw-text-gray-900">
        No service activity found
      </div>
      {tableModel.getCoreRowModel().rows.length !== 0 && (
        <span className="tw-text-base tw-font-normal tw-text-slate-500">
          Try adjusting your search filters
        </span>
      )}
    </div>
  )
}
