import { useState } from 'react'
import { FilterData, FilterValue } from './filterData.ts'

export type FilterGroupState = {
  map: Map<string, FilterData>
}

export type FilterGroupStateActions = {
  updateFilter: (key: string, filterValue: FilterValue) => void
  clearAllFilters: () => void
}

export function useFilterGroupState(
  activeFilters: Map<string, FilterData>
): [FilterGroupState, FilterGroupStateActions] {
  const [filtersMap, setFiltersMap] = useState<Map<string, FilterData>>(
    new Map(activeFilters)
  )

  return [
    filterGroupState(filtersMap),
    {
      updateFilter: (key: string, filterValue: FilterValue) => {
        if (!filterValue) {
          filtersMap.delete(key)
        } else {
          filtersMap.set(key, filterValue)
        }

        setFiltersMap(new Map(filtersMap))
      },
      clearAllFilters: () => {
        setFiltersMap(new Map<string, FilterData>())
      },
    },
  ]
}

export function filterGroupState(
  filtersMap: Map<string, FilterData>
): FilterGroupState {
  return {
    map: filtersMap,
  }
}
