import React, { ReactEventHandler } from 'react'
import { IconifyIcon } from '@iconify/react'
import { Icon } from '@iconify/react/offline'
import { Maybe } from '@/helpers/TypeHelpers.ts'

interface RichRadioButtonProps {
  readonly children: React.ReactNode
  readonly icon: IconifyIcon
  readonly iconColor: string
  readonly option: string
  readonly currentOption: Maybe<string>
  readonly onChange: ReactEventHandler
}

export default function RichRadioButton({
  children,
  icon,
  iconColor,
  onChange,
  option,
  currentOption,
}: RichRadioButtonProps): React.JSX.Element {
  return (
    <li className="tw-mb-3">
      <input
        id={option}
        type="radio"
        className="tw-peer tw-hidden"
        value={option}
        checked={option === currentOption}
        onChange={onChange}
      />
      <label
        htmlFor={option}
        className="tw-inline-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-blue-600 tw-bg-white tw-p-3 hover:tw-bg-gray-100 hover:tw-text-gray-600 peer-checked:tw-border-blue-600 peer-checked:tw-bg-blue-50 dark:tw-bg-gray-800 dark:tw-text-gray-400 dark:hover:tw-bg-gray-700"
      >
        <div className="tw-flex tw-items-center">
          <Icon icon={icon} width={20} height={20} color={iconColor} />
          <span className="tw-ml-1 tw-font-bold">{children}</span>
        </div>
      </label>
    </li>
  )
}
