import React from 'react'
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  Tooltip,
  XAxis,
  YAxis,
} from 'react-jsx-highstock'
import Highcharts, { BoostOptions, PlotOptions } from 'highcharts/highstock'
import {
  ACTUAL_ENERGY_SERIES_CONFIG,
  ACTUAL_VS_EXPECTED_SERIES_CONFIG,
  ACTUAL_VS_PREDICTED_SERIES_CONFIG,
  EXPECTED_ENERGY_SERIES_CONFIG,
  PREDICTED_ENERGY_SERIES_CONFIG,
  SOLAR_RESOURCE_RATIO_SERIES_CONFIG,
} from '@/components/graphs/types.ts'
import { GraphSeries } from '@/components/core/graphs/GraphSeries.tsx'
import { MonthlyPerformanceRow } from '@/generated'
import {
  createCategoryDataPoint,
  SeriesDataPoint,
} from '@/components/core/graphs/SeriesDataPoint.ts'
import { parseMonthlyBucket } from '@/helpers/MomentHelpers.ts'

const BOOST_OPTIONS: BoostOptions = {
  enabled: false,
}

const PLOT_OPTIONS: PlotOptions = {
  series: {
    animation: false,
  },
}

interface MonthlyPerformanceGraphProps {
  performanceRows: MonthlyPerformanceRow[]
}

export function MonthlyPerformanceGraph({
  performanceRows,
}: Readonly<MonthlyPerformanceGraphProps>): React.JSX.Element {
  const categories = performanceRows.map(r =>
    formatMonthlyBucketLabel(r.startDate, r.endDate)
  )

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart boost={BOOST_OPTIONS} plotOptions={PLOT_OPTIONS}>
        <Chart animation={false} />
        <Tooltip />
        <XAxis categories={categories} />

        <YAxis opposite={false}>
          <YAxis.Title>Energy (kWh)</YAxis.Title>
          <GraphSeries
            seriesConfig={ACTUAL_ENERGY_SERIES_CONFIG}
            data={performanceRows.map(r => createDataPoint(r.actualEnergy))}
          />
          <GraphSeries
            seriesConfig={PREDICTED_ENERGY_SERIES_CONFIG}
            data={performanceRows.map(r => createDataPoint(r.predictedEnergy))}
          />
          <GraphSeries
            seriesConfig={EXPECTED_ENERGY_SERIES_CONFIG}
            data={performanceRows.map(r => createDataPoint(r.expectedEnergy))}
          />
        </YAxis>

        <YAxis opposite={true}>
          <YAxis.Title>(%)</YAxis.Title>
          <GraphSeries
            seriesConfig={ACTUAL_VS_PREDICTED_SERIES_CONFIG}
            data={performanceRows.map(r =>
              createRatioDataPoint(r.actualVsPredictedEnergy)
            )}
          />
          <GraphSeries
            seriesConfig={ACTUAL_VS_EXPECTED_SERIES_CONFIG}
            data={performanceRows.map(r =>
              createRatioDataPoint(r.actualVsExpectedEnergy)
            )}
          />
          <GraphSeries
            seriesConfig={SOLAR_RESOURCE_RATIO_SERIES_CONFIG}
            data={performanceRows.map(r =>
              createRatioDataPoint(r.solarResourceRatio)
            )}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  )
}

function createDataPoint(value: number | null): SeriesDataPoint {
  const roundedValue = value ? Math.round(value) : null
  return createCategoryDataPoint(roundedValue)
}

function createRatioDataPoint(value: number | null): SeriesDataPoint {
  const scaledValue = value === null ? null : value * 100.0
  return createDataPoint(scaledValue)
}

function formatMonthlyBucketLabel(
  isoStartDate: string,
  isoEndDate: string
): string {
  const monthlyBucket = parseMonthlyBucket(isoStartDate, isoEndDate)
  if (!monthlyBucket) {
    return `${isoStartDate} - ${isoEndDate}`
  }
  if (monthlyBucket.isFullMonth) {
    return monthlyBucket.startDate.format(`MMM YYYY`)
  } else {
    return `${monthlyBucket.startDate.format('MMM DD')}-${monthlyBucket.endDate.format('DD YYYY')}`
  }
}
