import { CellContext, ColumnDef, Row, RowData } from '@tanstack/react-table'
import React from 'react'
import {
  DecimalNumberFormat,
  DefaultNumberFormat,
  formatNumber,
  IntegerNumberFormat,
  PercentNumberFormat,
} from '@/helpers/NumberFormats.ts'
import { Maybe, NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import { AlignType } from '@/components/core/table/TableStyles.ts'

/**
 * Sort columns that contain null values such that nulls
 * sre first.
 * @param rowA
 * @param rowB
 * @param columnId
 */
function numericSortWithNulls<T extends RowData>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
): number {
  const a = rowA.getValue<Maybe<number>>(columnId) ?? null
  const b = rowB.getValue<Maybe<number>>(columnId) ?? null
  if (a === b) return 0
  if (a === null) return -1
  if (b === null) return 1
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

export type NumberFormatFn = (value: Maybe<number>) => Maybe<string>

export const Formats = {
  default: (v: Maybe<number>): Maybe<string> =>
    formatNumber(v, DefaultNumberFormat({ whenMissing: '--' })),
  decimal: (v: Maybe<number>): Maybe<string> =>
    formatNumber(v, DecimalNumberFormat({ whenMissing: '--' })),
  percent: (v: Maybe<number>): Maybe<string> =>
    formatNumber(v, PercentNumberFormat({ whenMissing: '--' })),
  integer: (v: Maybe<number>): Maybe<string> =>
    formatNumber(v, IntegerNumberFormat({ whenMissing: '--' })),
}

function getFormatter(
  option?: keyof typeof Formats | NumberFormatFn
): NumberFormatFn {
  if (option instanceof Function) return option
  return Formats[option ?? 'default']
}

type Options<T extends RowData> = {
  key: NestedKeyOf<T>
  header?: string
  align?: AlignType
  mode?: 'sortable'
  format?: keyof typeof Formats | NumberFormatFn
  cell?: (ctx: CellContext<T, unknown>) => React.JSX.Element
}

export default function numericColumn<T>(options: Options<T>): ColumnDef<T> {
  const sortEnabled = options.mode === 'sortable'

  return {
    id: options.key,
    accessorKey: options.key,
    header: options.header,
    enableSorting: sortEnabled,
    sortingFn: numericSortWithNulls,
    cell:
      options.cell ??
      (({ cell }) =>
        getFormatter(options.format)(cell.getValue<Maybe<number>>())),
    meta: {
      align: options.align ?? 'right',
    },
  }
}
