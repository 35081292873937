import { Card } from '@/components/core/cards/Card.tsx'
import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import { ReactElement } from 'react'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'

type Props = {
  children: [
    ReactElement<typeof SnapshotSummary>,
    ReactElement<typeof SnapshotSections>,
  ]
}
export default function SnapshotCard({ children }: Readonly<Props>) {
  return <Card className="tw-h-full">{children}</Card>
}
