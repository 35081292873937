import { useAuth } from '../components/auth/AuthHooks.ts'
import { ConfigurationParameters } from '../generated'
import { useMemo } from 'react'

export function useAxiosClientConfig() {
  const auth = useAuth()
  return useMemo(() => {
    const config: ConfigurationParameters = {
      basePath: window.location.origin,
      accessToken: auth.accessToken,
    }
    return config
  }, [auth])
}
