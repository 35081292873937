import { Outlet, useParams } from 'react-router-dom'
import NotFoundPage from '../NotFoundPage.tsx'
import { Icon } from '@iconify/react/offline'
import iconLocation from '@iconify/icons-material-symbols/location-on'
import iconSolarPower from '@iconify/icons-material-symbols/solar-power'
import { IconifyIcon } from '@iconify/react'
import React, { PropsWithChildren } from 'react'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import { AssetMetadata } from '@/generated'
import { formatNumberKw } from '@/helpers/NumberFormats.ts'
import {
  AssetPerformanceTabRoute,
  AssetServiceActivityTabRoute,
} from './AssetDetailRoutes.tsx'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { NavTab, NavTabs } from '@/components/core/nav/NavTabs.tsx'

export type AssetDetailParams = {
  assetId?: string
}

export type AssetDetailContext = {
  assetId: number
}

export function AssetDetailPage(): React.JSX.Element {
  const params = useParams<AssetDetailParams>()
  const assetId = parseInt(params.assetId ?? '', 10)
  const assetMetadataQuery = useAssetMetadataQuery(assetId)

  if (isNaN(assetId)) {
    return <NotFoundPage />
  }
  if (assetMetadataQuery.isError) {
    // This will be handled by the global error handler modal. Could use some more nuance here
    // (e.g. identifying a 404 vs. a 500) to render a dedicated UI.
    // https://omnidian.atlassian.net/browse/HYP-2510 for finding a more holistic solution for exposing the status
    // code from the error and develop a holistic solution that could be applied to any page.
    throw assetMetadataQuery.error
  }

  const outletContext: AssetDetailContext = {
    assetId,
  }
  return (
    <div className="tw-flex tw-flex-row tw-overflow-y-hidden tw-bg-white">
      <div className="tw-w-[400px] tw-flex-none tw-overflow-y-auto">
        <AssetMetadataSection
          data={assetMetadataQuery.data}
        ></AssetMetadataSection>
      </div>
      <div className="tw-h-page tw-w-full tw-overflow-y-auto tw-border-l tw-border-slate-200 tw-p-6">
        <div className="tw-mb-6">
          <AssetDetailNavTabs />
        </div>
        <Outlet context={outletContext} />
      </div>
    </div>
  )
}

function AssetDetailNavTabs(): React.JSX.Element {
  const { assetPerformanceEnabled } = useFeatureFlags()
  return (
    <NavTabs>
      {assetPerformanceEnabled && (
        <NavTab title="Performance" path={AssetPerformanceTabRoute} />
      )}
      <NavTab title="Service Activity" path={AssetServiceActivityTabRoute} />
    </NavTabs>
  )
}

interface AssetMetadataSectionProps {
  data?: AssetMetadata
}

function AssetMetadataSection({ data }: Readonly<AssetMetadataSectionProps>) {
  if (!data) return <></>
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-pl-10 tw-pr-6">
      <div className="tw-text-xl tw-font-bold tw-text-gray-900">
        {data.assetName}
      </div>
      <AssetMetadataBlock title="Site Address" icon={iconLocation}>
        <div className="tw-text-slate-700">
          {data.address.street}, {data.address.city}, {data.address.state}{' '}
          {data.address.postalCode}
        </div>
      </AssetMetadataBlock>
      {data.systemSize !== null && (
        <AssetMetadataBlock title="System Size" icon={iconSolarPower}>
          <div className="tw-text-slate-700">
            {formatNumberKw(data.systemSize)}
          </div>
        </AssetMetadataBlock>
      )}
    </div>
  )
}

interface AssetMetadataBlockProps {
  title: string
  icon: IconifyIcon
}

function AssetMetadataBlock({
  title,
  icon,
  children,
}: Readonly<PropsWithChildren<AssetMetadataBlockProps>>) {
  return (
    <div className="tw-inline-flex tw-gap-4">
      <Icon className="tw-size-5" icon={icon} />
      <div className="tw-grow">
        <div className="tw-mb-0.5 tw-text-sm tw-text-slate-500">{title}</div>
        {children}
      </div>
    </div>
  )
}
