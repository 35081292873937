import { ComponentProps, PropsWithChildren } from 'react'
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

interface NavTabsProps
  extends PropsWithChildren<Omit<ComponentProps<'div'>, 'className'>> {
  path?: string
}

export function NavTabs({ path, ...props }: Readonly<NavTabsProps>) {
  const location = useLocation()
  const resolvedPath = useResolvedPath(path ?? '')
  const isActive = !path || location.pathname.startsWith(resolvedPath.pathname)

  return (
    <div
      {...props}
      className={twMerge(
        `tw-mb-0 tw-mt-2 tw-border-b tw-border-gray-200 tw-text-center tw-text-sm tw-font-medium tw-text-gray-500`,
        !isActive ? 'tw-hidden' : undefined
      )}
    >
      <ul className="tw--mb-px tw-flex tw-flex-wrap tw-p-0">
        {props.children}
      </ul>
    </div>
  )
}

interface TabProps {
  title: string
  path: string
  tabIndex?: number
  variant?: keyof typeof TabStyles
}

export function NavTab({
  variant = 'tab',
  tabIndex,
  path,
  title,
}: Readonly<TabProps>) {
  return (
    <li className="tw-m-0 tw-cursor-pointer tw-select-none tw-list-none tw-p-0">
      <NavLink
        to={path}
        tabIndex={tabIndex}
        className={({ isActive }) =>
          TabStyles[variant][isActive ? 'active' : 'inactive']
        }
      >
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-1">
          {title}
        </div>
      </NavLink>
    </li>
  )
}

const TabStyles = {
  tab: {
    active:
      'tw-no-underline tw-inline-block tw-bg-transparent tw-py-2 tw-px-4 tw-text-blue-600 tw-border-solid tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-blue-600 tw-rounded-t-lg',
    inactive:
      'tw-no-underline tw-inline-block tw-bg-transparent tw-py-2 tw-px-4 tw-text-slate-500 tw-border-solid tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-gray-200 tw-rounded-t-lg',
  },
  pill: {
    active:
      'tw-mb-2 tw-no-underline tw-inline-block tw-px-4 tw-py-2 tw-text-blue-600 tw-bg-blue-200 tw-rounded-lg',
    inactive:
      'tw-mb-2 tw-no-underline tw-border-0 tw-inline-block tw-bg-transparent tw-px-4 tw-py-2 tw-rounded-lg tw-text-blue-600 hover:tw-text-gray-900 hover:tw-bg-gray-100',
  },
}
