import SolarAssetsImage from '../assets/solar-assets.svg?react'

export default function NotFoundPage() {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <SolarAssetsImage />
      <div>Sorry, we could not find that page.</div>
    </div>
  )
}
