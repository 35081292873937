import { CellContext, ColumnDef } from '@tanstack/react-table'
import { ServiceActivityTableData } from '@/pages/serviceActivity/residential/types.ts'
import { ReactNode } from 'react'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import TwoValueTableElement from '@/pages/serviceActivity/residential/tableComponents/TwoValueTableElement.tsx'

type TwoValuesColumnProps<T> = {
  accessorKey: NestedKeyOf<ServiceActivityTableData>
  primaryHeader: string | ReactNode
  secondaryHeader: string | ReactNode
  accessorFn: (ctx: Readonly<CellContext<T, unknown>>) => {
    primary: string | ReactNode
    secondary: string | ReactNode
  }
  enableSorting?: boolean
}
export default function twoValuesColumn<T>({
  accessorKey,
  primaryHeader,
  secondaryHeader,
  accessorFn,
  enableSorting = false,
}: Readonly<TwoValuesColumnProps<T>>): ColumnDef<T> {
  return {
    accessorKey,
    header: () => (
      <TwoValueTableElement
        primary={primaryHeader}
        secondary={secondaryHeader}
      />
    ),
    cell: (ctx: Readonly<CellContext<T, unknown>>) => {
      return <TwoValueTableElement {...accessorFn(ctx)} />
    },
    enableSorting,
  }
}
