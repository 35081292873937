import { ColumnDef, RowData } from '@tanstack/react-table'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'

export type TextFilterMode =
  | 'sortable'
  | 'filterable-facets'
  | 'filterable-search'

type Options = {
  mode?: TextFilterMode
  noWrap?: boolean
  fixed?: boolean
  disableColumnFilter?: boolean
}

export function getTextFilterConfig<TData extends RowData, TValue>(
  options: Options
): Partial<ColumnDef<TData, TValue>> {
  if (options.mode === 'filterable-facets')
    return {
      enableColumnFilter: !options.disableColumnFilter,
      filterFn: facetFilterFn,
      meta: {
        type: 'filter-faceted',
        noWrap: options.noWrap,
        headerClassName: options.fixed ? 'tw-w-0' : undefined,
      },
    }

  if (options.mode === 'filterable-search')
    return {
      enableColumnFilter: !options.disableColumnFilter,
      meta: {
        type: 'filter-search',
        noWrap: options.noWrap,
        headerClassName: options.fixed ? 'tw-w-0' : undefined,
      },
    }

  return {
    meta: {
      noWrap: options.noWrap,
    },
  }
}
