import { Modal } from '@/components/core/modals/Modal.tsx'
import { ClientProfileSelector } from '@/components/clientprofile/ClientProfileSelector.tsx'

export interface ClientProfileSelectionModalProps {
  readonly clientProfileIds: string[]
  readonly onSetClientProfileId: (clientProfileId: string) => void
  readonly onClose: () => void
}

export function ClientProfileSelectionModal({
  clientProfileIds,
  onSetClientProfileId,
  onClose,
}: ClientProfileSelectionModalProps): React.JSX.Element {
  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header onClose={onClose}>Select Client</Modal.Header>
      <Modal.Body>
        <ClientProfileSelector
          clientProfileIds={clientProfileIds}
          onSetClientProfileId={onSetClientProfileId}
        />
      </Modal.Body>
    </Modal>
  )
}
