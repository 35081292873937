import { ServiceActivity } from '@/generated'
import { isTypeOfTicket } from '@/helpers/ServiceActivityHelper.ts'
import TicketSummaryModal from '@/components/modals/ticket-summary-modal/TicketSummaryModal.tsx'
import CaseViewModalContainer from '@/components/modals/case-view-modal/CaseViewModalContainer.tsx'
import { Portal } from '@headlessui/react'

interface ServiceActivityModalProps {
  serviceActivity: ServiceActivity
  onClose: () => void
}

export default function ServiceActivityModal({
  serviceActivity,
  onClose,
}: Readonly<ServiceActivityModalProps>) {
  if (isTypeOfTicket(serviceActivity)) {
    return (
      <Portal>
        <TicketSummaryModal
          show={true}
          serviceActivity={serviceActivity}
          onClose={onClose}
        />
      </Portal>
    )
  } else {
    return (
      <CaseViewModalContainer
        show={true}
        assetId={serviceActivity.assetId}
        caseId={serviceActivity.id}
        onClose={onClose}
      />
    )
  }
}
