import React, { ComponentProps, PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { BadgeColor } from './BadgeColor.ts'

interface BadgeProps extends PropsWithChildren<ComponentProps<'span'>> {
  readonly color: keyof typeof BadgeColor
}

export function Badge({ color, children }: BadgeProps): React.JSX.Element {
  const style = twMerge(
    'tw-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-px-3 tw-py-1 tw-font-medium',
    BadgeColor[color]
  )
  return <div className={style}>{children}</div>
}
