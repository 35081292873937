import iconLeftArrow from '@iconify/icons-heroicons/arrow-small-left'
import iconRightArrow from '@iconify/icons-heroicons/arrow-small-right'
import { Icon } from '@iconify/react/offline'
import moment from 'moment'
import React, { useMemo } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import {
  ISO_DATE_FORMAT,
  parseIsoDate,
  parseIsoMonth,
} from '@/helpers/MomentHelpers.ts'
import { Maybe } from '@/helpers/TypeHelpers.ts'

export interface CalendarProps {
  weekDayHeaderFormat?: 'dd' | 'ddd'
  showDaysNotInMonth?: boolean
  isoMinDate?: string
  isoMaxDate?: string
  isoCurrentMonth?: string
  selection?: DateSelectionModel
  disablePrevMonth?: boolean
  disableNextMonth?: boolean
  onPrevMonthClicked?: () => void
  onNextMonthClicked?: () => void
  onDayHover?: (isoDate: string) => void
  onDayClicked?: (isoDate: string) => void
}

export default function Calendar({
  selection = { kind: 'single', isoDate: null },
  ...props
}: Readonly<CalendarProps>): React.JSX.Element {
  const today = moment().startOf('day')

  const currentMonth =
    parseIsoMonth(props.isoCurrentMonth ?? null) ??
    moment(today).startOf('month')

  const minDate = parseIsoDate(props.isoMinDate ?? null)
  const maxDate = parseIsoDate(props.isoMaxDate ?? null)

  const prevMonthIsValid =
    minDate === null ||
    moment(currentMonth)
      .subtract(1, 'month')
      .endOf('month')
      .isSameOrAfter(minDate)

  const nextMonthIsValid =
    maxDate === null ||
    moment(currentMonth)
      .add(1, 'month')
      .startOf('month')
      .isSameOrBefore(maxDate)

  const cells = useMemo(
    () => generateCells(currentMonth, selection, minDate, maxDate),
    [currentMonth, selection, minDate, maxDate]
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="calendar"
      className="tw-max-w-fit tw-bg-white"
      onMouseDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div>
        <div className="tw-m-0 tw-flex tw-items-center tw-justify-between tw-p-0">
          <div className="tw-w-8 tw-shrink-0">
            {!props.disablePrevMonth &&
              prevMonthIsValid &&
              props.onPrevMonthClicked && (
                <button
                  data-testid="prevMonthButton"
                  type="button"
                  className="tw-flex tw-items-start tw-rounded-lg tw-border-0 tw-bg-white tw-py-2.5 tw-text-lg tw-text-gray-900 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-200"
                  onClick={props.onPrevMonthClicked}
                >
                  <Icon icon={iconLeftArrow} />
                </button>
              )}
          </div>
          <div className="tw-select-none tw-rounded-lg tw-border-0 tw-bg-white tw-px-5 tw-py-2.5 tw-text-sm tw-font-semibold tw-text-gray-900">
            {currentMonth.format('MMMM YYYY')}
          </div>
          <div className="tw-w-8 tw-shrink-0">
            {!props.disableNextMonth &&
              nextMonthIsValid &&
              props.onNextMonthClicked && (
                <button
                  data-testid="nextMonthButton"
                  type="button"
                  className="tw-flex tw-items-end tw-rounded-lg tw-border-0 tw-bg-white tw-py-2.5 tw-text-lg tw-text-gray-900 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-200"
                  onClick={props.onNextMonthClicked}
                >
                  <Icon icon={iconRightArrow} />
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-w-64 tw-cursor-pointer tw-select-none tw-grid-cols-7">
        {Array.from({ length: 7 }, (_, i) =>
          moment()
            .startOf('week')
            .add(i, 'days')
            .format(props.weekDayHeaderFormat ?? 'ddd')
        ).map(d => (
          <span
            key={d}
            className="tw-block tw-flex-1 tw-flex-nowrap tw-whitespace-nowrap tw-border-0 tw-px-1 tw-py-2 tw-text-center tw-text-sm tw-font-semibold tw-leading-9 tw-text-gray-500"
          >
            {d}
          </span>
        ))}
        {cells.map(cell => {
          const onHover = () => {
            if (
              !cell.isDisabled &&
              !!props.onDayHover &&
              selection.kind === 'range' &&
              (props.showDaysNotInMonth || cell.isWithinMonth)
            ) {
              props.onDayHover(cell.day.format(ISO_DATE_FORMAT))
            }
          }
          return (
            <button
              key={cell.id}
              className={cell.className}
              onFocus={onHover}
              onMouseOver={onHover}
              onClick={() => {
                if (
                  !cell.isDisabled &&
                  !!props.onDayClicked &&
                  (props.showDaysNotInMonth || cell.isWithinMonth)
                )
                  props.onDayClicked(cell.day.format(ISO_DATE_FORMAT))
              }}
            >
              {(props.showDaysNotInMonth || cell.isWithinMonth) &&
                cell.day.date()}
            </button>
          )
        })}
      </div>
    </div>
  )
}

function getCellClassName(cell: Cell) {
  return twMerge(
    THEME.day,
    cell.isWithinMonth && cell.isSelected ? THEME.selected : THEME.unselected,
    cell.isWithinMonth && cell.isSelectionStart
      ? THEME.startSelection
      : undefined,
    cell.isWithinMonth && cell.isSelectionEnd ? THEME.endSelection : undefined,
    cell.isDisabled ? THEME.disabled : undefined
  )
}

function getSelection(selection: DateSelectionModel) {
  function singleSelection(model: SingleDateSelectionModel) {
    const value =
      model.isoDate !== null ? parseIsoDate(model.isoDate, false) : undefined
    const result = value?.isValid() ? value : undefined
    return [result, result] as const
  }

  function rangeSelection(model: DateRangeSelectionModel) {
    const start =
      model.value.isoStartDate !== null
        ? parseIsoDate(model.value.isoStartDate)
        : null
    const end =
      model.value.isoEndDate !== null
        ? parseIsoDate(model.value.isoEndDate)
        : null

    return [start?.isValid() ? start : null, end?.isValid() ? end : null]
  }

  switch (selection.kind) {
    case 'single':
      return singleSelection(selection)
    case 'range':
      return rangeSelection(selection)
    default:
      return [null, null] as const
  }
}

function generateCells(
  currentMonth: moment.Moment,
  selectionModel: DateSelectionModel,
  minDate: Maybe<moment.Moment>,
  maxDate: Maybe<moment.Moment>
): Cell[] {
  const endOfMonth = moment(currentMonth).endOf('month')
  const endOfCalendar = moment(endOfMonth).endOf('week')

  const [selectionStart, selectionEnd] = getSelection(selectionModel)

  const hasValidSelection =
    selectionStart?.isSameOrBefore(selectionEnd) ?? false

  const isComplete =
    selectionModel.kind !== 'range' || selectionModel.isComplete

  const calendarDays: Cell[] = []
  const currentDay = moment(currentMonth).startOf('week')
  while (!currentDay.isAfter(endOfCalendar)) {
    const day = moment(currentDay)
    const cell: Cell = {
      id: day.unix(),
      day: day,
      isWithinMonth: moment(day).startOf('month').isSame(currentMonth),
      isSelectionStart: selectionStart?.isSame(day) ?? false,
      isSelectionEnd: isComplete && (selectionEnd?.isSame(day) ?? false),
      isSelected:
        hasValidSelection &&
        day.isBetween(selectionStart, selectionEnd, 'day', '[]'),
      isDisabled:
        (minDate !== null && day.isBefore(minDate)) ||
        (maxDate !== null && day.isAfter(maxDate)),
    }
    cell.className = getCellClassName(cell)
    calendarDays.push(cell)
    currentDay.add(1, 'day')
  }
  return calendarDays
}

/**
 * Internally derived state for a given day on the rendered calendar
 */
type Cell = {
  id: number
  day: moment.Moment
  isWithinMonth: boolean
  isSelected: boolean
  isSelectionStart: boolean
  isSelectionEnd: boolean
  isDisabled: boolean
  className?: string
}

export type DateSelectionModel =
  | SingleDateSelectionModel
  | DateRangeSelectionModel

export type SingleDateSelectionModel = {
  kind: 'single'
  isoDate: string | null
}

export type DateRangeSelectionModel = {
  kind: 'range'
  isComplete: boolean
  value: {
    isoStartDate: string | null
    isoEndDate: string | null
  }
}

const THEME = {
  day: `tw-mb-1 tw-py-2 tw-px-1 tw-block tw-flex-1 tw-leading-9 tw-border-0 tw-text-center tw-font-semibold tw-text-sm`,
  unselected: `tw-rounded-lg tw-text-gray-900 hover:tw-bg-gray-100`,
  selected: `tw-text-gray-900 tw-bg-gray-100 hover:tw-bg-gray-100`,
  startSelection: `tw-rounded-l-lg tw-text-gray-200 tw-bg-blue-700 hover:tw-bg-blue-700`,
  endSelection: `tw-rounded-r-lg tw-text-gray-200 tw-bg-blue-700 hover:tw-bg-blue-700`,
  disabled: `tw-cursor-default tw-text-gray-400 hover:tw-text-gray-400 hover:tw-bg-white`,
}
