import iconSearch from '@iconify/icons-material-symbols/search'
import { Icon } from '@iconify/react/offline'
import React, {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useEffect,
} from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

interface SearchInputProps extends Omit<ComponentProps<'input'>, 'onChange'> {
  value: string | number
  onChange?: (value: string | number) => void
  debounce?: number
  disabled?: boolean
}

function SearchInputComponent(
  {
    value: initialValue,
    onChange,
    debounce = 250,
    disabled,
    className,
    ...props
  }: SearchInputProps,
  ref?: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = React.useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // debounce to prevent spamming on change events
  useEffect(
    () => {
      if (onChange) {
        const timeoutId = setTimeout(() => {
          onChange(value)
        }, debounce)

        return () => {
          clearTimeout(timeoutId)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  return (
    <div className="tw-bg-white tw-pb-2">
      <div className="tw-relative tw-mt-1">
        <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
          <Icon
            icon={iconSearch}
            width={24}
            height={24}
            className="tw-text-blue-600"
          />
        </div>
        <input
          role="searchbox"
          type="search"
          ref={ref}
          {...props}
          className={twMerge(
            'tw-block tw-w-80 tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-50 tw-p-2 tw-pl-10 tw-text-sm tw-font-normal tw-text-gray-900 focus:tw-border-blue-500 focus:tw-ring-blue-500',
            className
          )}
          value={value}
          disabled={!!disabled}
          onChange={e => {
            setValue(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

// Wrap with forwardRef so users of this
// can get input element references to
// set the focus if needed.
const SearchInput = forwardRef(SearchInputComponent)

export default SearchInput
