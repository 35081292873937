import React, { PropsWithChildren } from 'react'
import { useFeatureFlagQuery } from '../../api/SpaQueries.ts'
import { FeatureFlags } from '../../generated'

interface RequireFeatureProps extends PropsWithChildren {
  readonly feature: keyof FeatureFlags
  readonly loading?: React.ReactNode
  readonly denied?: React.ReactNode
}

export function RequireFeature(props: RequireFeatureProps): React.JSX.Element {
  const featureFlagsQuery = useFeatureFlagQuery()
  if (!featureFlagsQuery.isFetched) return <>{props.loading}</>
  if (featureFlagsQuery.data?.[props.feature]) return <>{props.children}</>
  return <>{props.denied}</>
}
