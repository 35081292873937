import { SiteHeader } from './SiteHeader.tsx'
import { Outlet } from 'react-router-dom'

export function MainLayout() {
  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <div className="tw-flex tw-flex-col tw-items-start tw-self-stretch tw-bg-white tw-px-6 tw-py-0">
        <SiteHeader />
      </div>
      <div className="tw-inline-flex tw-max-h-page tw-flex-col tw-gap-8 tw-self-stretch tw-overflow-auto">
        <Outlet />
      </div>
    </div>
  )
}
