import { Column } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { RowData } from '@tanstack/table-core'
import {
  FacetFilterValue,
  useFacetFilterData,
} from '@/components/core/table/filters/facetFilterFn.ts'

export interface FacetFilterPopoverProps<TData extends RowData> {
  readonly column: Column<TData>
  readonly onClose: () => void
}

export default function HeaderFacetFilter<TData extends RowData>(
  props: FacetFilterPopoverProps<TData>
) {
  const facets = props.column.getFacetedUniqueValues()
  const [facetFilterData, facetFilterDataActions] = useFacetFilterData(
    (props.column.getFilterValue() as FacetFilterValue)?.selectedFacets
  )

  // NOTE: using useState here rather than useRef because the ref is used in an effect
  // that needs to run after rendering occurs and the ref gets updated
  const [initialFocusRef, setInitialFocusRef] =
    useState<HTMLInputElement | null>(null)

  useEffect(
    () => {
      if (initialFocusRef) {
        initialFocusRef.focus()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function apply() {
    props.column.setFilterValue(facetFilterData)
    props.onClose()
  }

  return (
    <div
      className={`tw-z-modal tw-cursor-pointer tw-select-none tw-divide-y tw-divide-gray-100 tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-text-gray-500 tw-shadow`}
    >
      <div className={`tw-m-0 tw-max-h-96 tw-select-none tw-overflow-y-auto`}>
        {Array.from(facets.keys())
          .sort((a: string, b: string) =>
            a.toString().localeCompare(b.toString())
          )
          .map((value: string, i) => (
            <label
              key={`${props.column.id}-${value}`}
              className={`tw-m-0 tw-block tw-w-full tw-cursor-pointer tw-px-4 tw-py-2 hover:tw-bg-gray-100`}
            >
              <input
                type="checkbox"
                className={`tw-m-0 tw-mr-2 tw-cursor-pointer tw-rounded tw-border-solid tw-border-gray-300 tw-bg-gray-100 tw-p-0 tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500`}
                checked={facetFilterData.selectedFacets.has(value.toString())}
                ref={i === 0 ? setInitialFocusRef : undefined}
                onChange={() => {
                  facetFilterDataActions.toggle(value.toString())
                }}
              />
              {value} ({facets.get(value)})
            </label>
          ))}
      </div>
      <div
        className={`tw-flex tw-select-none tw-flex-row tw-justify-around tw-gap-2 tw-border-t-2 tw-border-solid tw-border-t-gray-500 tw-p-2`}
      >
        <button
          onClick={facetFilterDataActions.clear}
          className={`tw-border-0 tw-bg-white tw-text-base tw-text-gray-500 tw-underline`}
        >
          Clear
        </button>
        <button
          onClick={apply}
          className={`tw-border-0 tw-bg-white tw-text-base tw-text-blue-700 tw-underline`}
        >
          Apply
        </button>
      </div>
    </div>
  )
}
