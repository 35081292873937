import React, { PropsWithChildren } from 'react'

function PageCardHeader({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-items-start tw-justify-start tw-gap-6 tw-p-6">
      {children}
    </div>
  )
}

function PageCardTitle({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <div className="tw-inline-flex tw-items-center tw-justify-start tw-gap-1.5">
      <div className="tw-flex tw-items-center tw-justify-start tw-gap-2.5">
        <div className="tw-text-base tw-font-bold tw-leading-normal tw-text-gray-900">
          {children}
        </div>
      </div>
      <div className="tw-relative tw-size-4" />
    </div>
  )
}
export function PageCard({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <div className="tw-rounded-2xl tw-bg-white tw-pb-4 tw-shadow-2xl">
      {children}
    </div>
  )
}

PageCard.Title = PageCardTitle
PageCard.Header = PageCardHeader
