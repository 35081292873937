import { ClientProfileSelector } from '../components/clientprofile/ClientProfileSelector.tsx'
import { useContext } from 'react'
import { ClientProfileContext } from '../contexts/ClientProfileProvider.tsx'
import { useClientProfileIdsQuery } from '../api/SpaQueries.ts'
import { LoadingPage } from './LoadingPage.tsx'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Display a list of avaialble client profiles for the user to select from. Upon selection, will redirect to the
 * URI specified in the 'originalUri' query parameter
 */
export function ClientProfileSelectionPage(): React.JSX.Element {
  const { setActiveClientProfileId } = useContext(ClientProfileContext)
  const clientProfileIdsQuery = useClientProfileIdsQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const clientProfileIds = clientProfileIdsQuery.data ?? []

  if (clientProfileIdsQuery.isLoading) {
    return <LoadingPage></LoadingPage>
  }
  if (clientProfileIdsQuery.isError || clientProfileIds.length === 0) {
    throw new Error('Unable to determine client data for the user')
  }

  return (
    <div
      className={
        'tw-flex tw-h-screen tw-items-start tw-justify-center tw-bg-slate-50 tw-pt-16'
      }
    >
      <div className="tw-rounded-lg tw-bg-white tw-p-6 tw-shadow">
        <h1 className="tw-mb-4 tw-text-2xl tw-font-bold tw-text-gray-900">
          Select client
        </h1>
        <ClientProfileSelector
          clientProfileIds={clientProfileIds}
          onSetClientProfileId={clientProfileId => {
            setActiveClientProfileId(clientProfileId)
            navigate(
              new URLSearchParams(location.search).get('originalUri') ?? '/',
              { replace: true }
            )
          }}
        />
      </div>
    </div>
  )
}
