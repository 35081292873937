import React, { PropsWithChildren, useContext } from 'react'
import Select from '@/components/core/form/Select.tsx'
import Checkbox from '@/components/core/form/Checkbox.tsx'
import {
  GraphControlsContext,
  GraphSeriesConfig,
} from '@/components/core/graphs/useGraphControls.ts'
import { GraphSeriesOptions } from '@/components/core/graphs/legend/types.ts'

export function GraphLegend({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return <div className="tw-flex tw-flex-col tw-gap-6">{children}</div>
}

function Group({ children }: Readonly<PropsWithChildren>): React.JSX.Element {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>
}

function GroupHeading({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <h3 className="tw-text-sm tw-font-medium tw-text-gray-900">{children}</h3>
  )
}

interface GroupSeriesTypeSelectProps {
  groupId: string
}

function GroupSeriesTypeSelect({
  groupId,
}: Readonly<GroupSeriesTypeSelectProps>): React.JSX.Element {
  const { state, actions } = useContext(GraphControlsContext)
  const group = state.getGroup(groupId)
  const value = group.seriesType
  return (
    <div
      data-testid={`graph-legend-group-series-select-${groupId}`}
      className="tw-my-2 tw-inline-flex tw-items-center tw-gap-2"
    >
      <div className="tw-text-sm tw-font-medium tw-text-gray-700">View as</div>
      <Select
        options={GraphSeriesOptions}
        value={GraphSeriesOptions.find(o => o.value === value)}
        onChange={newValue => {
          if (newValue !== null) {
            actions.setGroupSeriesType(groupId, newValue.value)
          }
        }}
      ></Select>
    </div>
  )
}

interface GroupSelectAllCheckboxProps {
  groupId: string
}

function GroupSelectAllCheckbox({
  groupId,
}: Readonly<GroupSelectAllCheckboxProps>) {
  const { state, actions } = useContext(GraphControlsContext)
  const checkboxState = state.getGroupSelectAllState(groupId)
  return (
    <div className="tw-my-2 tw-flex tw-h-4 tw-items-center">
      <Checkbox
        id={`${groupId}-all`}
        checked={checkboxState.checked}
        indeterminate={checkboxState.indeterminate}
        onChange={checked => {
          actions.setGroupEnabled(groupId, checked)
        }}
      />
      <label
        htmlFor={groupId}
        className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900"
      >
        <div className="tw-text-xs tw-text-gray-900">Select All</div>
      </label>
    </div>
  )
}

function Subheading({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <h4 className="tw-text-xs tw-font-medium tw-text-gray-700">{children}</h4>
  )
}

function SeriesList({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return <ul className="tw-flex tw-flex-col tw-gap-1 tw-p-0">{children}</ul>
}

interface SeriesProps {
  config: GraphSeriesConfig
}

function Series({
  config,
  children,
}: Readonly<PropsWithChildren<SeriesProps>>): React.JSX.Element {
  const { state, actions } = useContext(GraphControlsContext)
  const { id, color } = config

  return (
    <li className="tw-flex tw-h-4 tw-items-center">
      <Checkbox
        id={id}
        checked={state.isSeriesEnabled(id)}
        onChange={checked => {
          actions.setSeriesEnabled(id, checked)
        }}
      />
      <label
        htmlFor={id}
        className="tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900"
      >
        <div className="tw-flex tw-h-3 tw-items-center tw-justify-start tw-gap-1.5">
          {state.getSeriesType(id) === 'column' && (
            <div
              className="tw-size-3 tw-rounded-full"
              style={{ backgroundColor: color }}
            />
          )}
          {state.getSeriesType(id) === 'line' && (
            <div
              className="tw-h-0 tw-w-3 tw-border"
              style={{ borderColor: color }}
            />
          )}
          <div className="tw-text-xs tw-text-gray-700">{children}</div>
        </div>
      </label>
    </li>
  )
}

Group.displayName = 'GraphLegend.Group'
GraphLegend.Group = Group
GroupHeading.displayName = 'GraphLegend.GroupHeading'
GraphLegend.GroupHeading = GroupHeading
GroupSeriesTypeSelect.displayName = 'GraphLegend.GroupSeriesTypeSelect'
GraphLegend.GroupSeriesTypeSelect = GroupSeriesTypeSelect
GroupSelectAllCheckbox.displayName = 'GraphLegend.GroupSelectAllCheckbox'
GraphLegend.GroupSelectAllCheckbox = GroupSelectAllCheckbox
Subheading.displayName = 'GraphLegend.Subheading'
GraphLegend.Subheading = Subheading
SeriesList.displayName = 'GraphLegend.SeriesList'
GraphLegend.SeriesList = SeriesList
Series.displayName = 'GraphLegend.Series'
GraphLegend.Series = Series
