import RSelect, { type GroupBase, type Props } from 'react-select'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export default function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ classNames, id, ...props }: Props<Option, IsMulti, Group>) {
  return (
    <RSelect
      inputId={id}
      classNames={{
        ...classNames,
        control: props =>
          twMerge(
            '!tw-rounded !tw-border !tw-border-gray-300 !tw-bg-white',
            props.isFocused &&
              '!tw-border-blue-600 !tw-ring-1 !tw-ring-blue-600',
            classNames?.control?.(props)
          ),
        valueContainer: props =>
          twMerge('!tw-px-4 !tw-py-2', classNames?.valueContainer?.(props)),
        placeholder: props =>
          twMerge(
            '!tw-m-0 !tw-text-gray-500',
            classNames?.placeholder?.(props)
          ),
        input: props =>
          twMerge(
            '!tw-m-0 !tw-text-gray-900 [&>input]:!tw-m-0 [&>input]:!tw-ring-0',
            classNames?.input?.(props)
          ),
        option: props =>
          twMerge('!tw-px-4 !tw-py-2', classNames?.option?.(props)),
        indicatorSeparator: props =>
          twMerge('!tw-hidden', classNames?.indicatorSeparator?.(props)),
      }}
      isSearchable={false}
      {...props}
    />
  )
}
