import { Moment } from 'moment/moment'
import { useAxis } from 'react-jsx-highstock'
import React, { useEffect } from 'react'

interface SetAxisZoomDatesProps {
  zoomMinDate?: Moment
  zoomMaxDate?: Moment
}

/**
 * This component should be used inside a react-jsx-high* Axis tag (generally XAxis).
 * There's no config prop to set the zoom range (extremes) on an axis, so this component
 * exists to be a hook to call the Highcharts API directly to update the extremes.
 */
export default function SetAxisZoomDates({
  zoomMinDate,
  zoomMaxDate,
}: Readonly<SetAxisZoomDatesProps>): React.JSX.Element {
  const axis = useAxis()
  useEffect(() => {
    axis?.setExtremes(zoomMinDate?.valueOf(), zoomMaxDate?.valueOf())
  }, [zoomMinDate, zoomMaxDate, axis])
  return <></>
}
