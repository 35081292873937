import { useMemo } from 'react'
import { CsvColumnOption } from '@/components/export/ExportCsvButton.tsx'
import { ServiceActivity } from '@/generated'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'

type ServiceActivityExportColumnOptions = {
  includeAssetDetails?: boolean
  includeClosedDate?: boolean
}

export default function useServiceActivityCsvColumns(
  options?: ServiceActivityExportColumnOptions
) {
  const showAssetManagerColumn = useHasMultipleAssetManagers()
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()

  // The following is a mapping of the columns to include in the CSV export.
  // key is the field in the JSON data and header is the value that gets output
  // in the CSV header row that the end user sees.
  return useMemo<CsvColumnOption<ServiceActivity>[]>(
    () => [
      ...((options?.includeAssetDetails && showAssetManagerColumn
        ? [{ key: 'assetManagerName', header: 'Asset Manager' }]
        : []) as CsvColumnOption<ServiceActivity>[]),
      ...((options?.includeAssetDetails
        ? [{ key: 'assetName', header: 'Asset Name' }]
        : []) as CsvColumnOption<ServiceActivity>[]),
      ...((serviceCloudIntegrationEnabled
        ? [
            { key: 'caseNumber', header: 'Case Number' },
            { key: 'workOrdersCount', header: 'Work Orders' },
          ]
        : [
            { key: 'id', header: 'Ticket ID' },
          ]) as CsvColumnOption<ServiceActivity>[]),
      { key: 'createdAt', header: 'Creation Date' },
      { key: 'priority', header: 'Priority' },
      { key: 'subject', header: 'Subject' },
      { key: 'creationReason', header: 'Creation Reason' },
      { key: 'status', header: 'Status' },
      ...((serviceCloudIntegrationEnabled
        ? []
        : [
            { key: 'subStatus', header: 'Progress' },
            { key: 'scheduleDate', header: 'Schedule Date' },
          ]) as CsvColumnOption<ServiceActivity>[]),
      { key: 'updatedAt', header: 'Last Updated' },
      { key: 'summary', header: 'Case Summary' },
      ...((serviceCloudIntegrationEnabled && options?.includeClosedDate
        ? [{ key: 'closedAt', header: 'Closed Date' }]
        : []) as CsvColumnOption<ServiceActivity>[]),
      ...((serviceCloudIntegrationEnabled
        ? [
            {
              key: 'pendingClientAction',
              header: 'Pending Action',
              accessorFn: val => (val.pendingClientAction ? 'YES' : 'NO'),
            },
          ]
        : []) as CsvColumnOption<ServiceActivity>[]),
    ],
    [
      options?.includeAssetDetails,
      options?.includeClosedDate,
      showAssetManagerColumn,
      serviceCloudIntegrationEnabled,
    ]
  )
}
