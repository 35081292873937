import { useContext } from 'react'
import { ServiceActivityTableContext } from '@/pages/serviceActivity/residential/tableComponents/context/ServiceActivityTableContext.tsx'

export default function useServiceActivityTableContext() {
  const context = useContext(ServiceActivityTableContext)
  if (context === undefined) {
    throw new Error(
      'useServiceActivityContext must be used within a ServiceActivityProvider'
    )
  }
  return context
}
