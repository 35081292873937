import { PropsWithChildren, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { useClientProfileIdsQuery } from '../../api/SpaQueries.ts'
import { ClientProfileContext } from '../../contexts/ClientProfileProvider.tsx'
import { LoadingPage } from '../../pages/LoadingPage.tsx'

/**
 * Ensures that the user has an appropriate client profile selected. In the event that only one profile is available
 * for the user to use, will transparently select that and proceed. Otherwise, will redirect to the selection page.
 */
export function RequireClientProfile(
  props: Readonly<PropsWithChildren>
): React.JSX.Element {
  const { activeClientProfileId, setActiveClientProfileId } =
    useContext(ClientProfileContext)
  const clientProfileIdsQuery = useClientProfileIdsQuery()
  const navigate = useNavigate()

  useEffect(() => {
    const clientProfileIds = clientProfileIdsQuery.data ?? []
    if (clientProfileIdsQuery.isLoading) return
    else if (
      clientProfileIds.length === 1 &&
      clientProfileIds[0] !== activeClientProfileId
    ) {
      // Only 1 available client, use it.
      setActiveClientProfileId(clientProfileIds[0])
    } else if (!activeClientProfileId) {
      // Need to redirect user to select which client to view.
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      navigate(
        `/select-client?originalUri=${encodeURIComponent(originalUri)}`,
        { replace: true }
      )
    }
  }, [
    activeClientProfileId,
    clientProfileIdsQuery,
    navigate,
    setActiveClientProfileId,
  ])

  if (clientProfileIdsQuery.isError) {
    throw new Error('Unable to determine client data for the user')
  }
  if (clientProfileIdsQuery.isLoading || !activeClientProfileId) {
    return <LoadingPage></LoadingPage>
  }

  return <>{props.children}</>
}
