import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import Table from '@/components/core/table/Table.tsx'
import useServiceActivityTableContext from '@/pages/serviceActivity/residential/tableComponents/context/useServiceActivityTableContext.ts'

export default function ServiceActivityTable() {
  const { tableModel } = useServiceActivityTableContext()
  return (
    <div className="tw-p-4">
      <Table
        className="tw-shrink"
        model={tableModel}
        loading={false}
        error={false}
        rowClassName={() => 'hover:tw-bg-slate-50'}
        onRowClick={() => null}
        noDataMessage={
          <ServiceActivityTableNoDataMessage tableModel={tableModel} />
        }
      />
    </div>
  )
}
