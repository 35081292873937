import { useState } from 'react'

export interface AvatarProps {
  fullName?: string
  profileImageUrl?: string
}

export function Avatar(props: Readonly<AvatarProps>) {
  const [invalidProfileImage, setInvalidProfileImage] = useState(false)

  if (props.profileImageUrl && !invalidProfileImage) {
    return (
      <img
        alt={props.fullName}
        className="tw-size-8 tw-rounded-full tw-ring-2 tw-ring-omnidian-orange"
        src={props.profileImageUrl}
        onError={() => {
          setInvalidProfileImage(true)
        }}
      />
    )
  }

  const match = props.fullName?.match(/\b(\w)/gi)
  const firstInitial = match && match.length > 0 ? match[0] : null
  const lastInitial = match && match.length > 1 ? match[match.length - 1] : null

  if (firstInitial) {
    return (
      <div className="tw-relative tw-inline-flex tw-size-8 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-yellow-200 tw-ring-2 tw-ring-omnidian-orange">
        <span
          data-testid="avatar-initials"
          className="tw-font-medium tw-text-gray-600"
        >
          {firstInitial}
          {lastInitial}
        </span>
      </div>
    )
  }

  return (
    <div className="tw-relative tw-inline-flex tw-size-8 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-gray-300 tw-ring-2 tw-ring-gray-400">
      <svg
        data-testid="avatar-unknown-image"
        className="tw-absolute tw-size-8 tw-text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        ></path>
      </svg>
    </div>
  )
}
