import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { useWorkOrdersContext } from './providers/WorkOrdersProvider.tsx'
import { statusToBadgeColor } from '@/helpers/ServiceActivityHelper.ts'

export default function WorkOrdersList() {
  const { workOrders, selected, onSelect } = useWorkOrdersContext()

  return (
    <>
      <div className="tw-border-b tw-border-gray-200 tw-px-4 tw-pb-4 tw-pt-6 tw-font-bold tw-text-black">
        Work Orders ({workOrders.length})
      </div>

      <ul className="tw-py-4">
        {workOrders.map(
          ({ id, workOrderNumber, subject, status, subStatus }) => (
            <li key={id}>
              <button
                className={twMerge(
                  'tw-flex tw-w-full tw-items-start tw-justify-between tw-gap-2.5 tw-border-b tw-border-gray-200 tw-py-2 tw-pl-4 tw-pr-2 hover:tw-bg-blue-50',
                  id === selected.id && 'tw-bg-blue-50'
                )}
                onClick={() => {
                  onSelect(id)
                }}
              >
                <div className="tw-flex tw-flex-col tw-items-start tw-text-left tw-text-sm">
                  <span className="tw-text-gray-900">
                    {subject ?? workOrderNumber ?? id}
                  </span>
                  <span className="tw-text-gray-500">{subStatus}</span>
                </div>

                <div className="tw-flex tw-shrink-0 tw-flex-col tw-items-end tw-text-xs">
                  <Badge color={statusToBadgeColor(status)}>{status}</Badge>
                </div>
              </button>
            </li>
          )
        )}
      </ul>
    </>
  )
}
