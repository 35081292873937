import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark'
import { FilterDrawerFilterDef } from './FiltersDrawerButton.tsx'
import { FilterGroupState } from '@/components/core/table/filters/useFilterGroupState.ts'

interface ActiveFiltersProps<T> {
  readonly filterDefs: Array<FilterDrawerFilterDef<T>>
  readonly appliedFilters: FilterGroupState
  readonly onClearFilter: (key: string) => void
  readonly onClearAll: () => void
}

export function ActiveFilters<T>({
  filterDefs,
  appliedFilters,
  onClearFilter,
  onClearAll,
}: ActiveFiltersProps<T>) {
  return (
    <div data-testid="active-filters">
      <div className="tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
        Active filters:
      </div>
      <div className="tw-inline-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2">
        {filterDefs.map(filter => {
          const appliedFilter = appliedFilters.map.get(filter.key)
          if (appliedFilter?.text) {
            return (
              <div
                key={filter.key}
                className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-slate-100 tw-px-3 tw-py-1"
              >
                <span className="tw-whitespace-nowrap tw-text-xs tw-font-medium tw-text-slate-500">
                  {filter.header}:&nbsp;
                </span>
                <span className="tw-text-xs tw-font-medium tw-text-gray-900">
                  {appliedFilter.text}
                </span>
                <button
                  type="button"
                  className="tw-ml-2 tw-rounded-lg tw-bg-transparent tw-text-sm tw-text-gray-600 hover:tw-bg-gray-200 hover:tw-text-gray-900"
                  aria-label="Clear"
                  onClick={() => {
                    onClearFilter(filter.key)
                  }}
                >
                  <span className="tw-sr-only">Clear</span>
                  <Icon
                    icon={iconClose}
                    className="tw-size-4"
                    aria-hidden="true"
                  />
                </button>
              </div>
            )
          }
        })}
        <button
          type="button"
          className="tw-rounded-full tw-border tw-border-slate-300 tw-bg-white tw-px-3 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-600 hover:tw-bg-gray-200"
          onClick={onClearAll}
        >
          Clear all
        </button>
      </div>
    </div>
  )
}
