/* eslint-disable */
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  FilterFn,
  filterFns,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

const NO_SORT: ColumnSort[] = []

// IMPORTANT! This empty array is a static singleton instances because
// it is given to the useTable hook when no data from the API is availble.
// Usually this is because the API call failed with an error status. Because the
// rows array is used as a dependency for useMemo for things like the totals row
// if these instances change we can cause an infinite re-render loop.
const EMPTY_ARRAY: unknown[] = []

export type TableModel<TData extends RowData> = ReactTable<TData> & {
  globalFilter: string
  resetAllFilters: () => void
}

export type UseTableOptions<T extends RowData> = {
  pageSize?: number
  filterFns?: Record<string, FilterFn<any>>
  canRowExpand?: (row: Row<T>) => boolean
  initialSort?: SortingState
}

export default function useTable<TData extends RowData, TValue>(
  data: TData[] | undefined,
  columns: ColumnDef<TData, TValue>[],
  options?: UseTableOptions<TData>
): TableModel<TData> {
  const [sorting, setSorting] = useState<SortingState>(
    options?.initialSort ?? NO_SORT
  )
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: options?.pageSize ?? 10_000,
  })

  const table = useReactTable<TData>({
    data: data ?? (EMPTY_ARRAY as TData[]),
    columns: columns,
    filterFns: options?.filterFns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
      pagination,
    },
    sortDescFirst: false,
    enableSortingRemoval: false,
    globalFilterFn: filterFns.includesString,
    getRowCanExpand: options?.canRowExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
  })

  return {
    ...table,
    globalFilter,
    resetAllFilters: () => {
      table.setGlobalFilter('')
      table.setColumnFilters([])
      table.setSorting([])
    },
  }
}