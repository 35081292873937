import type { PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

interface WorkOrderTabProps {
  selected?: boolean
}

export default function WorkOrderTab({
  selected,
  children,
}: PropsWithChildren<WorkOrderTabProps>) {
  return (
    <div
      className={twMerge(
        'tw-px-4 tw-pb-3 tw-pt-1 tw-text-sm tw-font-medium tw-text-gray-500',
        selected && 'tw-border-b tw-border-blue-600 tw-text-blue-600'
      )}
    >
      {children}
    </div>
  )
}
