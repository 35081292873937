import { CellContext } from '@tanstack/react-table'
import React from 'react'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Formats } from '@/components/core/table/columns/numericColumn.ts'

export function ActualVsExpectedCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const value = ctx.getValue<Maybe<number>>()
  return (
    <div className={twMerge('tw-font-bold', getActualVsExpectedColor(value))}>
      {Formats.percent(value)}
    </div>
  )
}

function getActualVsExpectedColor(value: Maybe<number>): Maybe<string> {
  if (value === undefined || value === null) return undefined
  if (value >= 0.95) return 'tw-text-green-500'
  if (value < 0.95 && value >= 0.85) return 'tw-text-yellow-400'
  if (value < 0.85 && value >= 0.75) return 'tw-text-orange-500'
  else return 'tw-text-red-600'
}
