import { GraphSeriesConfig } from '@/components/core/graphs/useGraphControls.ts'

export const GRAPH_GROUP_IDS = {
  siteProduction: 'site-production',
  performanceRatios: 'performance-ratios',
  solarResourceRatio: 'solar-resource-ratio',
}

export const ACTUAL_ENERGY_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'site-actual',
  name: 'Actual Energy (kWh)',
  color: '#517AF9',
}
export const PREDICTED_ENERGY_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'site-predicted',
  name: 'Predicted Energy (kWh)',
  color: '#ED7E4E',
}
export const EXPECTED_ENERGY_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'site-expected',
  name: 'Expected Energy (kWh)',
  color: '#CFDBFE',
}
export const ACTUAL_VS_PREDICTED_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'actual-vs-predicted',
  name: 'Actual vs Predicted (%)',
  color: '#EA580C',
  lineDashStyle: 'Dash',
}
export const ACTUAL_VS_EXPECTED_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'actual-vs-expected',
  name: 'Actual vs Expected (%)',
  color: '#5F6F85',
  lineDashStyle: 'Dash',
}
export const ACTUAL_VS_EXPECTED_7DAY_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'actual-vs-expected-7day',
  name: 'Actual vs Expected - Trailing 7 Days (%)',
  color: '#EA580C',
  lineDashStyle: 'Dash',
}
export const SOLAR_RESOURCE_RATIO_SERIES_CONFIG: GraphSeriesConfig = {
  id: 'solar-resource-ratio',
  name: 'Solar Resource Ratio (%)',
  color: '#1A368E',
  lineDashStyle: 'Dash',
}
