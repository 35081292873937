import type { PropsWithChildren } from 'react'

interface HorizontalPropertyItemProps {
  label: string
}

export default function HorizontalPropertyItem({
  label,
  children,
}: PropsWithChildren<HorizontalPropertyItemProps>) {
  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      <span className="tw-font-bold tw-text-gray-900">{label}</span>
      <span className="tw-text-sm tw-text-gray-500">{children}</span>
    </div>
  )
}
