import { DateRangePreset } from '@/components/core/datepickers/DateRangeSelector.tsx'
import {
  customDateRangeFilterData,
  DateRangeFilterValue,
  presetDateRangeFilterData,
} from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { DATE_INPUT_FORMAT, ISO_DATE_FORMAT } from '@/helpers/MomentHelpers.ts'
import DateInput from '@/components/core/form/DateInput.tsx'
import moment from 'moment'
import { FilterDrawerDateRangeFilterDef } from './FiltersDrawerButton.tsx'
import { FilterDrawerSection } from './FilterDrawerSection.tsx'
import { Maybe } from '@/helpers/TypeHelpers.ts'

interface FilterDrawerDateRangeSectionProps<T> {
  readonly filterDef: FilterDrawerDateRangeFilterDef<T>
  readonly filterValue: DateRangeFilterValue
  readonly onStateChange: (state: DateRangeFilterValue) => void
}

export function FilterDrawerDateRangeSection<T>({
  filterDef,
  filterValue,
  onStateChange,
}: FilterDrawerDateRangeSectionProps<T>) {
  const selection = filterValue?.selection

  const isCustomSelected = selection?.type === 'custom'
  const isoStartDate = filterValue?.startDate?.format(ISO_DATE_FORMAT)
  const isoEndDate = filterValue?.endDate?.format(ISO_DATE_FORMAT)

  function isPresetSelected(preset: DateRangePreset) {
    return (
      selection?.type === 'preset' &&
      selection.preset.isoStartDate === preset.isoStartDate &&
      selection.preset.isoEndDate === preset.isoEndDate
    )
  }

  function selectPreset(preset: DateRangePreset) {
    onStateChange(presetDateRangeFilterData(preset))
  }

  function selectCustom() {
    onStateChange(customDateRangeFilterData(null, null))
  }

  const onClear = () => {
    onStateChange(null)
  }

  return (
    <FilterDrawerSection header={filterDef.header} onClear={onClear}>
      <div>
        {filterDef.presets.map(preset => {
          const key = `${filterDef.key}-${preset.name}`
          return (
            <div key={key} className="tw-flex tw-items-center tw-py-2">
              <input
                className="tw-size-4 tw-border-gray-300 tw-bg-gray-100 tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500"
                id={key}
                type="radio"
                value={preset.name}
                checked={isPresetSelected(preset)}
                onChange={() => {
                  selectPreset(preset)
                }}
              />
              <label
                htmlFor={key}
                className="tw-ml-2 tw-text-sm tw-text-slate-700"
              >
                {preset.name}
              </label>
            </div>
          )
        })}
        <div className="tw-flex tw-items-center tw-py-2">
          <input
            className="tw-size-4 tw-border-gray-300 tw-bg-gray-100 tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500"
            id={`${filterDef.key}:custom`}
            type="radio"
            value="custom"
            checked={isCustomSelected}
            onChange={selectCustom}
          />
          <label
            htmlFor={`${filterDef.key}:custom`}
            className="tw-ml-2 tw-text-sm tw-text-slate-700"
          >
            Custom
          </label>
        </div>
      </div>
      {isCustomSelected && (
        <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-3 tw-pt-3">
          <DateInput
            placeholder="Select start date"
            name="startDate"
            label="Start date"
            format={DATE_INPUT_FORMAT}
            value={isoStartDate}
            isoMaxDate={isoEndDate}
            allowedFloatingPlacements={['bottom-start', 'top-start']}
            onChange={e => {
              onStateChange(
                customDateRangeFilterData(
                  parseDateInputDate(e.target.value),
                  filterValue?.endDate
                )
              )
            }}
          ></DateInput>
          <DateInput
            placeholder="Select end date"
            name="endDate"
            label="End date"
            format={DATE_INPUT_FORMAT}
            value={isoEndDate}
            isoMinDate={isoStartDate}
            allowedFloatingPlacements={['bottom-end', 'top-end']}
            onChange={e => {
              onStateChange(
                customDateRangeFilterData(
                  filterValue?.startDate,
                  parseDateInputDate(e.target.value)
                )
              )
            }}
          ></DateInput>
        </div>
      )}
    </FilterDrawerSection>
  )
}

function parseDateInputDate(value: string): Maybe<moment.Moment> {
  const date = moment(value, DATE_INPUT_FORMAT, true)
  return date.isValid() ? date : null
}
