import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'

type Value = {
  value: number
  percentage: string
}
type Props = {
  oneMonth: Value
  twoMonths: Value
  threeMonths: Value
  threeMonthsPlus: Value
}
export default function AgeSnapshot({
  oneMonth,
  twoMonths,
  threeMonths,
  threeMonthsPlus,
}: Readonly<Props>) {
  return (
    <SnapshotSection>
      <SnapshotLine label="New (1-29 days)">
        <SnapshotValue value={oneMonth.value} />
        <SnapshotValue value={oneMonth.percentage} displayType="badge" />
      </SnapshotLine>
      <SnapshotLine label="Aging (30-59 days)">
        <SnapshotValue value={twoMonths.value} />
        <SnapshotValue value={twoMonths.percentage} displayType="badge" />
      </SnapshotLine>
      <SnapshotLine label="Aging (60-89 days)">
        <SnapshotValue value={threeMonths.value} />
        <SnapshotValue value={threeMonths.percentage} displayType="badge" />
      </SnapshotLine>
      <SnapshotLine label="Aging (90+ days)">
        <SnapshotValue value={threeMonthsPlus.value} />
        <SnapshotValue value={threeMonthsPlus.percentage} displayType="badge" />
      </SnapshotLine>
    </SnapshotSection>
  )
}
