import useServiceActivityTableContext from '@/pages/serviceActivity/residential/tableComponents/context/useServiceActivityTableContext.ts'

export default function ServiceActivityTableControls() {
  const { tableModel } = useServiceActivityTableContext()
  return (
    <div className="tw-flex tw-w-full tw-flex-row tw-items-end tw-justify-end tw-gap-4">
      <div className="tw-text-gray-500">
        Service Activity:&nbsp;
        {tableModel.getFilteredRowModel().rows.length}
      </div>
    </div>
  )
}
