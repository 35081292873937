import React, { useState } from 'react'
import { CellContext } from '@tanstack/react-table'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { Portal } from '@headlessui/react'
import IconHistory from '@/assets/icons/history.svg?react'
import { Modal } from '@/components/core/modals/Modal.tsx'
import Button from '@/components/core/buttons/Button.tsx'

interface TicketSummaryModalProps {
  readonly ticketSubject: string
  readonly ticketSummary?: string
  readonly onClose: () => void
}

function TicketSummaryModal(props: TicketSummaryModalProps) {
  return (
    <div className="tw-z-modal">
      <Modal show={true} onClose={props.onClose}>
        <Modal.Header onClose={props.onClose}>
          {props.ticketSubject} Case Summary
        </Modal.Header>
        <Modal.Body>
          <div className="tw-whitespace-pre-line">
            {props.ticketSummary ?? ''}
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-flex-row tw-justify-end">
          <Button onClick={props.onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export function TicketSummaryCell<T>(
  getSubject: (ctx: CellContext<T, unknown>) => string
) {
  return function TicketSummaryCell(
    ctx: Readonly<CellContext<T, unknown>>
  ): React.JSX.Element {
    const [showSummary, setShowSummary] = useState(false)
    const ticketSubject = getSubject(ctx)
    const summary = ctx.getValue<string | undefined>()
    const clickHandler = summary
      ? () => {
          setShowSummary(true)
        }
      : undefined
    return (
      <button
        className="tw-flex tw-cursor-pointer tw-flex-row tw-justify-center"
        onClick={clickHandler}
      >
        {summary && (
          <Tooltip
            content={
              <>
                View Case
                <br />
                Summary
              </>
            }
          >
            <IconHistory title="history" />
          </Tooltip>
        )}
        {showSummary && (
          <Portal>
            <TicketSummaryModal
              ticketSubject={ticketSubject}
              ticketSummary={summary}
              onClose={() => {
                setShowSummary(false)
              }}
            />
          </Portal>
        )}
      </button>
    )
  }
}
