import React from 'react'
import { CellContext } from '@tanstack/react-table'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export function WorkOrdersCountCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  const workOrdersCount = ctx.getValue<number | null>()
  return (
    <span
      className={twMerge(
        workOrdersCount !== null &&
          'tw-inline-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-50 tw-text-blue-600'
      )}
    >
      {workOrdersCount ?? 'n/a'}
    </span>
  )
}
