import { Tooltip } from 'react-jsx-highstock'
import React from 'react'

/* https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat */
const DAILY_GRAPH_TOOLTIP_FORMAT = '%A %b %d, %Y'
const HOURLY_GRAPH_TOOLTIP_FORMAT = '%b %d, %Y %H:%M'

/**
 * We are able to define the tooltip format for the granularity that Highcharts picks for the data points.
 * How highcharts picks the granularity is opaque, and varies depending on the graph range. Even for
 * graphs with only daily data points, Highcharts may select the hourly granularity for display.
 *
 * As a result, we provide different settings based on whether the caller specifies the graph is an
 * hourly or daily one, so that the tooltip formats remain consistent in the graph.
 *
 * See https://api.highcharts.com/highcharts/tooltip.dateTimeLabelFormats
 */
const GRANULARITY_TOOLTIP_FORMATS = {
  hourly: {
    minute: HOURLY_GRAPH_TOOLTIP_FORMAT,
    hour: HOURLY_GRAPH_TOOLTIP_FORMAT,
    day: HOURLY_GRAPH_TOOLTIP_FORMAT,
  },
  daily: {
    minute: DAILY_GRAPH_TOOLTIP_FORMAT,
    hour: DAILY_GRAPH_TOOLTIP_FORMAT,
    day: DAILY_GRAPH_TOOLTIP_FORMAT,
  },
}

export interface TimeSeriesTooltipProps {
  dataGranularity: keyof typeof GRANULARITY_TOOLTIP_FORMATS
}

export default function TimeSeriesTooltip({
  dataGranularity,
}: Readonly<TimeSeriesTooltipProps>): React.JSX.Element {
  return (
    <Tooltip
      enabled={true}
      dateTimeLabelFormats={GRANULARITY_TOOLTIP_FORMATS[dataGranularity]}
    />
  )
}
