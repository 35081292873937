import { PropsWithChildren, useState } from 'react'
import expandLessIcon from '@iconify/icons-material-symbols/expand-less'
import expandMoreIcon from '@iconify/icons-material-symbols/expand-more'
import { Icon } from '@iconify/react/offline'

interface FiltersDrawerSectionProps extends PropsWithChildren {
  readonly header: string
  readonly onClear: () => void
}

export function FilterDrawerSection({
  header,
  onClear,
  children,
}: FiltersDrawerSectionProps) {
  const [expandRow, setExpandRow] = useState(false)
  const expandIcon = expandRow ? expandLessIcon : expandMoreIcon

  return (
    <>
      <button
        className="tw-flex tw-w-full tw-items-start tw-border-b tw-border-slate-200 tw-py-4 tw-text-left tw-text-sm"
        onClick={() => {
          setExpandRow(!expandRow)
        }}
      >
        <div className="tw-grow tw-text-base tw-font-bold tw-text-gray-900">
          {header}
        </div>
        <div>
          <Icon
            icon={expandIcon}
            className="tw-inline tw-text-gray-700"
            width={24}
            height={24}
          />
        </div>
      </button>
      {expandRow && (
        <div className="tw-relative tw-py-2">
          <button
            onClick={onClear}
            className="tw-absolute tw-right-0 tw-top-4 tw-border-0 tw-bg-white tw-text-sm tw-text-blue-600"
          >
            Clear
          </button>

          {children}
        </div>
      )}
    </>
  )
}
