import React, { PropsWithChildren } from 'react'
import Highcharts from 'highcharts/highstock'
import { HighchartsProvider } from 'react-jsx-highstock'
import highchartsAccessibility from 'highcharts/modules/accessibility'

highchartsAccessibility(Highcharts)

/**
 * Simple wrapper to output the context provider necessary for react-jsx-high*, and enable the
 * accessibility module.
 */
export default function HighchartsWrapper({
  children,
}: Readonly<PropsWithChildren>): React.JSX.Element {
  return (
    <HighchartsProvider Highcharts={Highcharts}>{children}</HighchartsProvider>
  )
}
