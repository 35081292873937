import React, { useMemo, useState } from 'react'
import { useBrandingQuery, useServiceActivityQuery } from '@/api/ApiQueries.ts'
import { CellContext, ColumnDef, Row } from '@tanstack/react-table'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { LoadingPage } from '../../LoadingPage.tsx'
import Table from '@/components/core/table/Table.tsx'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'
import { TicketStatusCell } from '@/components/table/cells/TicketStatusCell.tsx'
import { DateCell } from '@/components/core/table/cells/DateCell.tsx'
import SearchInput from '@/components/core/table/SearchInput.tsx'
import { PageCard } from '@/components/cards/PageCard.tsx'
import {
  FilterDrawerFilterDef,
  FiltersDrawerButton,
} from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { ActiveFilters } from '@/components/core/drawers/filter/ActiveFilters.tsx'
import { ExportCsvButton } from '@/components/export/ExportCsvButton.tsx'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { dateRangeFilterFn } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { ServiceActivity } from '@/generated'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'
import { useTableFilterState } from '@/components/core/table/filters/useTableFitlerState.ts'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import {
  assetManagerFilter,
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  scheduledDateFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  subStatusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { formatCSVRow } from '@/helpers/ServiceActivityHelper.ts'
import { WorkOrdersCountCell } from '@/components/table/cells/WorkOrdersCountCell.tsx'
import { ServiceActivityStatusCell } from '@/components/table/cells/ServiceActivityStatusCell.tsx'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'
import ServiceActivityModal from '@/components/modals/service-activity-modal/ServiceActivityModal.tsx'
import useServiceActivityCsvColumns from '@/pages/serviceActivity/useServiceActivityCsvColumns.ts'
import PendingClientActionCell from '@/components/table/cells/PendingClientActionCell.tsx'
import { pendingClientActionSortFn } from '@/components/table/sort/pendingClientActionSort.ts'

const emptyServiceActivityData: ServiceActivity[] = []

export function ServiceActivityPageCommercial(): React.JSX.Element {
  return <ServiceActivityTable />
}

function ServiceActivityTable(): React.JSX.Element {
  const { data: branding } = useBrandingQuery()
  const serviceActivityQuery = useServiceActivityQuery()
  const showAssetManagerColumn = useHasMultipleAssetManagers()
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<ServiceActivity>()

  const columns = useMemo<ColumnDef<ServiceActivity>[]>(
    () => [
      ...((showAssetManagerColumn
        ? [
            textColumn({
              key: 'assetManagerName',
              header: 'Asset Manager',
              mode: 'filterable-facets',
              disableColumnFilter: true,
            }),
          ]
        : []) as ColumnDef<ServiceActivity>[]),
      textColumn({
        key: 'assetName',
        header: 'Asset Name',
        cell: AssetNameCell,
      }),
      ...((serviceCloudIntegrationEnabled
        ? [
            textColumn({
              key: 'caseNumber',
              header: 'Case Number',
            }),
            {
              header: 'Work Orders',
              accessorKey: 'workOrdersCount',
              enableSorting: false,
              cell: WorkOrdersCountCell,
            },
          ]
        : [
            textColumn({
              key: 'id',
              header: 'Ticket ID',
            }),
          ]) as ColumnDef<ServiceActivity>[]),
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        enableSorting: true,
        header: 'Creation Date',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
        enableSorting: true,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority?.toString()
        ),
        filterFn: facetFilterFn,
        cell: TicketPriorityCell,
      },
      textColumn({
        key: 'subject',
        header: 'Subject',
      }),
      textColumn({
        key: 'creationReason',
        header: 'Creation Reason',
        mode: 'filterable-facets',
        disableColumnFilter: true,
      }),
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        cell: ServiceActivityStatusCell,
        filterFn: facetFilterFn,
      },
      ...((serviceCloudIntegrationEnabled
        ? []
        : [
            textColumn({
              key: 'subStatus',
              header: 'Progress',
              cell: TicketStatusCell,
              mode: 'filterable-facets',
              disableColumnFilter: true,
            }),
            {
              id: 'scheduleDate',
              accessorKey: 'scheduleDate',
              enableSorting: true,
              header: 'Schedule Date',
              filterFn: dateRangeFilterFn,
              cell: DateCell,
            },
          ]) as ColumnDef<ServiceActivity>[]),
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        enableSorting: true,
        header: 'Last Updated',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      ...((serviceCloudIntegrationEnabled
        ? [
            {
              id: 'pendingClientAction',
              accessorKey: 'pendingClientAction',
              enableSorting: true,
              sortingFn: pendingClientActionSortFn,
              header: 'Pending Action',
              filterFn: facetFilterFn,
              accessorFn: serviceActivityPendingClientActionFilterDataAccessor,
              cell: PendingClientActionCell,
            },
          ]
        : []) as ColumnDef<ServiceActivity>[]),
    ],
    [showAssetManagerColumn, serviceCloudIntegrationEnabled]
  )
  const exportCsvColumns = useServiceActivityCsvColumns({
    includeAssetDetails: true,
  })

  const filterDefs = useMemo<
    Array<FilterDrawerFilterDef<ServiceActivity>>
  >(() => {
    const defs: FilterDrawerFilterDef<ServiceActivity>[] = []
    if (showAssetManagerColumn) {
      defs.push(
        assetManagerFilter(
          'assetManagerName',
          rowData => rowData.assetManagerName
        )
      )
    }
    defs.push(
      creationDateFilter('createdAt'),
      priorityFilter(
        'priority',
        rowData => rowData.priority?.toString() ?? null
      ),
      creationReasonFilter('creationReason', rowData => rowData.creationReason),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      ),
      ...((serviceCloudIntegrationEnabled
        ? []
        : [
            subStatusFilter('subStatus', rowData => rowData.subStatus),
            scheduledDateFilter('scheduleDate'),
          ]) as FilterDrawerFilterDef<ServiceActivity>[]),
      updatedDateFilter('updatedAt'),
      ...((serviceCloudIntegrationEnabled
        ? [
            pendingClientActionFilter(
              'pendingClientAction',
              serviceActivityPendingClientActionFilterDataAccessor
            ),
          ]
        : []) as FilterDrawerFilterDef<ServiceActivity>[])
    )
    return defs
  }, [showAssetManagerColumn, serviceCloudIntegrationEnabled])

  const rows = serviceActivityQuery.data ?? emptyServiceActivityData
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
  })
  const [tableFilterState, tableFilterStateActions] =
    useTableFilterState(tableModel)
  const disableFilterControls = rows.length === 0

  const handleClickRow = (row: Row<ServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const handleCloseModal = () => {
    setSelectedServiceActivity(undefined)
  }

  return (
    <div className="tw-p-8">
      <PageCard>
        <PageCard.Header>
          <PageCard.Title>Open Service Activity</PageCard.Title>
          <div>
            <span className="tw-text-sm tw-font-normal tw-text-slate-500">
              The following table shows all open service activity{' '}
              {branding?.name ? `for ${branding.name}` : ''}.
            </span>{' '}
          </div>
          <div className="tw-h-px tw-w-full tw-bg-slate-700/10" />
          <div className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-gap-4 tw-text-base tw-font-normal tw-text-gray-500">
            <div className="tw-grow">
              <SearchInput
                value={tableModel.globalFilter}
                onChange={value => {
                  tableModel.setGlobalFilter(String(value))
                }}
                placeholder="Search"
                disabled={disableFilterControls}
              />
            </div>
            <div className="tw-space-x-4">
              <FiltersDrawerButton
                filterDefs={filterDefs}
                rowData={serviceActivityQuery.data}
                appliedFilters={tableFilterState.activeFilters}
                disabled={disableFilterControls}
                onApplyFilters={tableFilterStateActions.applyFilters}
              />
              <Tooltip
                content={`Export current table\nview, including any\napplied filters`}
              >
                <ExportCsvButton
                  fileName="service_activity"
                  columns={exportCsvColumns}
                  rows={() =>
                    tableModel
                      .getSortedRowModel()
                      .rows.map(r => formatCSVRow(r.original))
                  }
                />
              </Tooltip>
            </div>
          </div>
          <div className="tw-flex tw-w-full tw-flex-row tw-items-end tw-justify-end tw-gap-4">
            {tableFilterState.hasActiveFilters && (
              <div className="tw-grow">
                <ActiveFilters
                  filterDefs={filterDefs}
                  appliedFilters={tableFilterState.activeFilters}
                  onClearFilter={tableFilterStateActions.clearFilter}
                  onClearAll={tableFilterStateActions.clearAllFilters}
                />
              </div>
            )}
            <div className="tw-text-gray-500">
              Service Activity:&nbsp;
              {tableModel.getFilteredRowModel().rows.length}
            </div>
          </div>
        </PageCard.Header>
        <div className="tw-mx-4 tw-overflow-auto">
          {serviceActivityQuery.isFetching && <LoadingPage />}
          {serviceActivityQuery.isFetched && (
            <Table
              className="tw-shrink"
              model={tableModel}
              loading={serviceActivityQuery.isFetching}
              error={serviceActivityQuery.isError}
              rowClassName={() => 'hover:tw-bg-slate-50'}
              onRowClick={handleClickRow}
              noDataMessage={
                <ServiceActivityTableNoDataMessage tableModel={tableModel} />
              }
            />
          )}
          {selectedServiceActivity && (
            <ServiceActivityModal
              serviceActivity={selectedServiceActivity}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </PageCard>
    </div>
  )
}

function AssetNameCell(
  ctx: Readonly<CellContext<ServiceActivity, unknown>>
): React.JSX.Element {
  const assetId = ctx.row.original.assetId
  const assetName = ctx.getValue<Maybe<string>>()
  return <AssetDetailLink assetId={assetId}>{assetName}</AssetDetailLink>
}
