import { extendTailwindMerge } from 'tailwind-merge'

/**
 * The default twMerge function only merges classes that it can identify as tailwind css classes.
 * Since we've added a prefix to tailwind classes to be consistent with Resolv3,
 * we need to configure twMerge to be aware of the new prefix.
 */
export const twMerge = extendTailwindMerge({
  prefix: 'tw-',
})
