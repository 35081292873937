// Utility types that are handy for functions in this file.
import { Row } from '@tanstack/react-table'

type RowComparator<T> = (a: Row<T>, b: Row<T>) => 0 | 1 | -1
type PriorityGetter<T> = (t: Row<T>) => string | undefined
const ticketPrioritySortMap = new Map<string, number>([
  ['Urgent', 0],
  ['High', 1],
  ['Normal', 2],
  ['Low', 3],
])

export function ticketPrioritySort(
  priorityA: string,
  priorityB: string
): 0 | 1 | -1 {
  const p1 = ticketPrioritySortMap.get(priorityA) ?? 1_000_000
  const p2 = ticketPrioritySortMap.get(priorityB) ?? 1_000_000
  if (p1 < p2) return -1
  if (p1 > p2) return 1
  return 0
}

export function ticketPriorityTableRowSort<T>(
  getPriority: PriorityGetter<T>
): RowComparator<T> {
  return (rowA: Row<T>, rowB: Row<T>) =>
    ticketPrioritySort(getPriority(rowA) ?? '', getPriority(rowB) ?? '')
}
