import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { PropsWithChildren, ReactNode, useRef, useState } from 'react'

interface TooltipProps extends PropsWithChildren {
  content: ReactNode
}

export function Tooltip(props: Readonly<TooltipProps>) {
  const [isOpen, setIsOpen] = useState(false)

  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({ mainAxis: 10 }),
      autoPlacement({
        allowedPlacements: ['top', 'bottom', 'left', 'right'],
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const hover = useHover(context, {
    restMs: 150,
    delay: { open: 500, close: 0 },
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  if (!props.content) return <>{props.children}</>

  return (
    <>
      <span
        className="tw-m-0 tw-p-0"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {props.children}
      </span>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="tw-z-modal tw-whitespace-pre-line tw-rounded-lg tw-bg-gray-700 tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-medium tw-text-white tw-shadow-sm"
        >
          {props.content}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="tw-fill-gray-700"
          />
        </div>
      )}
    </>
  )
}
